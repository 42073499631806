import { Component, Input, OnInit } from '@angular/core';
import { SiteSubscriptionService } from '../backend-api/site-subscription/site-subscription.service';
import { Site } from '../backend-api/site/site';
import { SiteService } from '../backend-api/site/site.service';
import { AuthService } from '../_services/auth.service';

@Component({
  selector: 'app-site-subscribe',
  templateUrl: './site-subscribe.component.html',
  styleUrls: ['./site-subscribe.component.scss'],
})
export class SiteSubscribeComponent implements OnInit {
  @Input() siteId: number;
  @Input() isSubscribed: boolean = null;

  constructor(
    private siteSubscriptionService: SiteSubscriptionService,
    private authService: AuthService,
    private siteService: SiteService
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.siteId && this.isSubscribed === null)
      this.isSubscribed = await this.checkIfSubscribed();
  }

  private async checkIfSubscribed(): Promise<boolean> {
    const userSubscriptions = (
      await this.siteSubscriptionService.list({
        user: this.authService.loggedInUser.user_id,
        site: this.siteId,
      })
    ).results;

    return userSubscriptions.length > 0;
  }

  toggleSubscribe(event: Event) {
    event.stopPropagation();
    this.isSubscribed
      ? this.siteService.unsubscribe(this.siteId)
      : this.siteService.subscribe(this.siteId);
    this.isSubscribed = !this.isSubscribed
  }
}
