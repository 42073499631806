import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LRMixin } from '../api-mixins';
import { LRCache } from '../api-cache';
import { SiteInterface } from './site';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../_services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class SiteService extends LRMixin<SiteInterface> {
  protected modelBaseUrl: string;

  protected lrCache = new LRCache(5 * 60 * 1000);

  constructor(protected http: HttpClient) {
    super(environment.BACKEND_BASE_URL + 'site/', http);
  }

  subscribe(siteId: number) {
    let url = `${this.modelBaseUrl}${siteId}/subscribe/`;
    return this.http.get(url).toPromise();
  }
  unsubscribe(siteId: number) {
    let url = `${this.modelBaseUrl}${siteId}/unsubscribe/`;
    return this.http.get(url).toPromise();
  }
}
