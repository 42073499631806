import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  VibrationReport,
  VibrationReportVerboseInterface,
} from '../../backend-api/vibration-report/vibration-report';
import { VibrationReportVerboseService } from '../../backend-api/vibration-report/vibration-report-verbose.service';
import { VibrationReportService } from '../../backend-api/vibration-report/vibration-report.service';

@Component({
  selector: 'app-vibration-report-dialog',
  templateUrl: './vibration-report-dialog.component.html',
  styleUrls: ['./vibration-report-dialog.component.scss'],
})
export class VibrationReportDialogComponent {
  reportVerbose: VibrationReport;
  loadingPdf: boolean = false;
  loadingVerbose: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<VibrationReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { vibrationReportId: number },
    private vibrationReportVerboseService: VibrationReportVerboseService,
    private vibrationReportService: VibrationReportService
  ) {
    const id = data.vibrationReportId;
    this.vibrationReportVerboseService
      .retrieve(id)
      .then((vibrationReportVerbose: VibrationReportVerboseInterface) => {
        this.reportVerbose = new VibrationReport(vibrationReportVerbose);
        this.loadingVerbose = false;
      });
  }

  async savePdf(): Promise<void> {
    this.loadingPdf = true;
    await this.vibrationReportService.downloadReportPdf(this.reportVerbose.id);
    this.loadingPdf = false;
  }

  close(): void {
    this.dialogRef.close();
  }
}
