<div class="main">
  <div class="upload-job">
    <h1>Data upload</h1>
    <p class="step-description">Upload offline vibration measurements and/or attachments with additional measurement
      information.</p>

    <!-- Step 1 -->
    <div *ngIf="currentStep === 1" class="step">
      <div></div>
      <h3 class="step-header">Step 1</h3>
      <p class="step-description">Please select the relevant vessel for the upload.</p>

      <mat-form-field class="select-site-form">
        <mat-label>
          <div style="display: flex;">
            Select vessel
            <mat-spinner *ngIf="!sites" style="margin-left: 5px;" diameter=15></mat-spinner>
          </div>
        </mat-label>
        <input type="text" placeholder="Select site" aria-label="Select site" matInput
          [formControl]="selectSiteFormControl" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option (onSelectionChange)="selectSite(site)" *ngFor="let site of filteredSites | async"
            [value]="site.name">
            {{site.name}}
          </mat-option>
        </mat-autocomplete>

        <button type="button" mat-button *ngIf="showSelectSiteClearButton()" matSuffix mat-icon-button
          aria-label="Clear" (click)="deselectSite()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

    </div>

    <!-- Step 2 -->
    <div *ngIf="currentStep === 2" class="step">
      <h3 class="step-header">Step 2</h3>
      <p class="step-description">Upload vibration measurement files (.odx).</p>

      <div class=" drag-drop-file-container" (click)="fileInput.click()" appDragDrop
        (onFileDropped)="onSourceFileAddition($event)"
       >
        <input hidden type="file" multiple #fileInput (change)="onSourceFileAddition($event.target.files)">

        <div class="elements-center">
          <mat-icon [inline]="false">attach_file</mat-icon> Add vibration measurement files
        </div>
      </div>
    </div>

    <!-- Step 3 -->
    <div *ngIf="currentStep === 3" class="step">
      <h3 class="step-header">Step 3</h3>
      <p class="step-description">Additional comments. E.g. performed maintenance, running conditions (speed, load,
        ect.).</p>

      <mat-form-field appearance="fill" class="comment-text">
        <mat-label>Upload comment</mat-label>
        <textarea matInput [formControl]="commentFormControl" rows=10></textarea>
      </mat-form-field>
    </div>

    <!-- Step 4 -->
    <div *ngIf="currentStep === 4" class="step">
      <h3 class="step-header">Step 4</h3>
      <p class="step-description">Upload extra attachments.</p>

      <div class=" drag-drop-file-container" (click)="attachmentInput.click()" appDragDrop
        (onFileDropped)="onAttachmentAddition($event)"
       >
        <input hidden type="file" multiple #attachmentInput (change)="onAttachmentAddition($event.target.files)">

        <div class="elements-center">
          <mat-icon [inline]="false">attach_file</mat-icon> Add extra attachments
        </div>
      </div>

      <div class="error-box" *ngIf="isSubmitError">
        Unable to submit upload. Please try again.
      </div>
    </div>

    <!-- Step 5 submitting -->
    <div *ngIf="currentStep === 5" class="step">
      <div class="step-submit" *ngIf="!isSubmitSuccess && !isSubmitError">
        <mat-spinner diameter=80></mat-spinner>
        <p *ngIf="isCreatingUploadJob" class="submit-description">Submitting upload ...</p>
        <p *ngIf="!isCreatingUploadJob" class="submit-description">Waiting for files to finish ...</p>
      </div>
      <div class="step-submit" *ngIf="isSubmitSuccess">
        <i class="far fa-check-circle submit-icon-success"></i>
        <p class="submit-description">Upload successful!</p>
      </div>
      <div class="step-submit" *ngIf="isSubmitError">
        <i class="far fa-times-circle submit-icon-error"></i>
        <p class="submit-error-text">Error while uploading</p>
        <button mat-raised-button color="primary" (click)="fixUploadErrors()">Retry</button>
      </div>
    </div>

    <!-- Buttons -->
    <div class="next-prev-button-group">
      <button *ngIf="currentStep > 1 && currentStep < 5" class="previous-button" mat-raised-button color="primary"
        (click)="goToPreviousStep()">Previous</button>
      <button *ngIf="currentStep<4" [disabled]="!selectedSite" class="next-button" mat-raised-button color="primary"
        (click)="goToNextStep()">Next</button>

      <button *ngIf="currentStep === 4" [disabled]="!isFiles()"
        matTooltip="{{isFiles() ? 'Submit upload' : 'No files to submit'}}" class="next-button" mat-raised-button
        color="primary" (click)="confirmBeforeSubmit()">Submit</button>
    </div>
  </div>

  <!-- Files summary -->
  <div class="files-summary-container">
    <div *ngIf="sourceFileUploadObjects.length>0">
      <div class="file-summary-header">Vibration measurement files</div>

      <!-- Source file list -->
      <div class="files-list" *ngFor="let uploadObject of sourceFileUploadObjects; let i= index">
        <p class="filename"> {{ uploadObject.file.name }}</p>
        <mat-progress-bar *ngIf="uploadObject.isUploading()" class="upload-bar" mode="determinate"
          value="{{uploadObject.uploadProgress}}"></mat-progress-bar>
        <p *ngIf="uploadObject.isUploading()" style="width: 46px; white-space: nowrap;">
          {{uploadObject.uploadProgress}} %
        </p>
        <div class="file-uploading-spinner" *ngIf="uploadObject.isCreatingSourceFile || uploadObject.isFinishingUpload">
          <mat-spinner diameter="18"> </mat-spinner>
        </div>
        <i *ngIf="uploadObject.isError()" class="fas fa-exclamation-triangle file-upload-error-icon"
          matTooltip="Error while uploading source file. Please try again"></i>

        <i *ngIf="uploadObject.isFinished" class="fas fa-check file-upload-complete-icon"
          matTooltip="File uploaded"></i>

        <i class="fas fa-trash delete-upload-icon" matTooltip="Remove file" *ngIf="!isSubmitting && !isSubmitSuccess"
          (click)="removeSourceFileUpload(uploadObject, i)"></i>
      </div>
    </div>

    <div *ngIf="attachmentUploadObjects.length>0">
      <div class="file-summary-header">Attachments</div>

      <!-- Attachment list -->
      <div class="files-list" *ngFor="let uploadObject of attachmentUploadObjects; let i= index">
        <p class="filename"> {{ uploadObject.file.name }}</p>
        <mat-progress-bar *ngIf="uploadObject.isUploading()" class="upload-bar" mode="determinate"
          value="{{uploadObject.uploadProgress}}"></mat-progress-bar>
        <p *ngIf="uploadObject.isUploading()" style="width: 46px; white-space: nowrap;">
          {{uploadObject.uploadProgress}} %
        </p>
        <div class="file-uploading-spinner" *ngIf="uploadObject.isCreatingSourceFile || uploadObject.isFinishingUpload">
          <mat-spinner diameter="18"> </mat-spinner>
        </div>
        <i *ngIf="uploadObject.isError()" class="fas fa-exclamation-triangle file-upload-error-icon"
          matTooltip="Error while uploading source file. Please try again"></i>

        <i *ngIf="uploadObject.isFinished" class="fas fa-check file-upload-complete-icon"
          matTooltip="File uploaded"></i>

        <i class="fas fa-trash delete-upload-icon" matTooltip="Remove file" *ngIf="!isSubmitting && !isSubmitSuccess"
          (click)="removeAttachmentUpload(uploadObject, i)"></i>
      </div>
    </div>
  </div>
</div>
