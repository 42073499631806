import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { SiteInterface } from '../backend-api/site/site';
import { SiteService } from '../backend-api/site/site.service';
import { VibrationReportService } from '../backend-api/vibration-report/vibration-report.service';
import { KpiService } from 'src/app/backend-api/kpi/kpi.service';
import { isTrash } from '../ts-tools/type-check';
import { DateAdapter } from '@angular/material/core';
import { SiteGroup } from '../backend-api/site-group/site-group';
import { SiteGroupFamily } from '../backend-api/site-group-family/site-group-family';
import { SiteSubscriptionService } from '../backend-api/site-subscription/site-subscription.service';
import { AuthService } from '../_services/auth.service';
import { argSort } from 'src/app/ts-tools/sort';
import * as XLSX from 'xlsx';

interface KPITableRow {
  subscribed: boolean;
  siteName: string;
  siteGroup: SiteGroup;
  siteGroupFamily: SiteGroupFamily;
  siteFlag: string;
  siteType: string;
  site: SiteInterface;
  nmStat: number;
  alarmStat: number;
  ranking: number;
  totalNmReports: number;
  totalReports: number;
  totalMachinesInAlarm: number;
  totalMachines: number;
  isLoadingKpiData: boolean;
}

@Component({
  selector: 'app-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss'],
})
export class KpiComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('KPI_TABLE_DIV') table: ElementRef;

  constructor(
    private siteService: SiteService,
    private VibrationReportService: VibrationReportService,
    private kpiService: KpiService,
    private dateAdapter: DateAdapter<Date>,
    private siteSubscriptionService: SiteSubscriptionService,
    private authService: AuthService
  ) {
    dateAdapter.setLocale('en-in'); // Set date in datepicker to DD/MM/YYYY
  }
  kpiTableDataSource: MatTableDataSource<KPITableRow>;
  kpiTableRows = [];
  kpiTableColumns: string[] = [
    'subscribed',
    'siteName',
    'siteGroup',
    'siteFlag',
    'siteType',
    'nmStat',
    'alarmStat',
    'ranking',
    'loading',
  ];
  destroy$ = new Subject<void>();
  initDone = false;
  startDate: Date = null;
  endDate: Date = null;
  sites: SiteInterface[] = [];
  defaultRanking = Infinity;

  async ngOnInit(): Promise<void> {
    let startDate = new Date();
    startDate.setDate(new Date().getDate() - 90);
    this.startDate = startDate;
    this.endDate = new Date();
    this.sites = await this.getSites();
    let tableRows = this.createKpiTableRowsFromSites();
    this.createKpiTableDataSource(tableRows);
    this.updateSubscriptions();
    this.onDate();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  onSearchBoxChange(event: Event) {
    const searchString = (event.target as HTMLInputElement).value;
    this.kpiTableDataSource.filter = searchString.trim().toLowerCase();
  }

  async onDate(): Promise<void> {
    if (this.startDate && this.endDate) {
      this.kpiTableDataSource.data.forEach((row) => {
        this.fetchKpiDataOnRow(row);
      });
    }
  }
  /**
   * Get sites
   */
  async getSites(): Promise<SiteInterface[]> {
    let results = (
      await this.siteService.list({ active: true, page_size: 10000 })
    ).results;
    return results;
  }

  createKpiTableDataSource(tableRows: KPITableRow[]) {
    this.kpiTableDataSource = new MatTableDataSource(tableRows);
    this.kpiTableDataSource.paginator = this.paginator;
    this.kpiTableDataSource.sort = this.sort;
  }

  async fetchKpiDataOnRow(row: KPITableRow) {
    let current_start_date = this.startDate;
    let current_end_date = this.endDate;
    row.isLoadingKpiData = true;
    row.nmStat = null;
    row.alarmStat = null;
    row.totalMachines = null;
    row.totalMachinesInAlarm = null;
    row.totalReports = null;
    row.totalNmReports = null;
    row.ranking = this.defaultRanking;

    let nmOverTotalRes = await this.kpiService.nmOverTotal(
      row.site.id,
      this.startDate,
      this.endDate
    );

    let totalNmReports = nmOverTotalRes.reports_nm;
    let totalReports = nmOverTotalRes.reports;

    let nmStat: number = null;
    if (totalReports > 0) {
      nmStat = (totalNmReports / totalReports) * 100;
    }

    let alarmOverTotalRes = await this.kpiService.alarmOverTotal(
      row.site.id,
      this.startDate,
      this.endDate
    );

    //If dates has changed, skip updating row and return here.
    if (current_start_date !== this.startDate) return;
    if (current_end_date !== this.endDate) return;

    let totalMachinesInAlarm = alarmOverTotalRes.totalMachinealarm;
    let totalMachines = alarmOverTotalRes.totalMachines;
    let alarmStat: number = null;
    if (totalMachines > 0) {
      alarmStat = (totalMachinesInAlarm / totalMachines) * 100;
    }

    row.nmStat = nmStat;
    row.alarmStat = alarmStat;
    row.totalMachines = totalMachines;
    row.totalMachinesInAlarm = totalMachinesInAlarm;
    row.totalReports = totalReports;
    row.totalNmReports = totalNmReports;

    this.evaluateRanking();
    row.isLoadingKpiData = false;
    this.kpiTableDataSource._updateChangeSubscription();
  }

  createKpiTableRowsFromSites(): KPITableRow[] {
    let kpiTableRows: KPITableRow[] = [];

    this.sites.forEach((site) => {
      kpiTableRows.push({
        subscribed: false,
        siteName: site.name,
        siteGroup: site.site_group_detail,
        siteGroupFamily: site.site_group_detail?.site_group_family_detail,
        siteFlag: site.country,
        siteType: site.site_type,
        site: site,
        nmStat: null,
        alarmStat: null,
        ranking: this.defaultRanking,
        totalMachines: null,
        totalMachinesInAlarm: null,
        totalReports: null,
        totalNmReports: null,
        isLoadingKpiData: false,
      });
    });

    return kpiTableRows;
  }

  statToString(stat: number) {
    if (isTrash(stat)) return '–';
    return `${stat.toFixed(2)} %`;
  }

  calculateScore(nmStat: number, alarmStat: number): number {
    // If no reports this interval, nmStat is null.
    if (isTrash(nmStat)) return Infinity;
    // If no machines on site, alarmStat is null
    if (isTrash(alarmStat)) return Infinity;
    return (nmStat + alarmStat) / 2;
  }

  evaluateRanking() {
    let scores: number[] = [];
    let score: number = null;
    this.kpiTableDataSource.data.forEach((row, row_index) => {
      score = this.calculateScore(row.nmStat, row.alarmStat);
      scores.push(score);
    });
    let sortArgs = argSort(scores);

    sortArgs.forEach((sortArg, i) => {
      if (scores[sortArg] === Infinity) {
        this.kpiTableDataSource.data[sortArg].ranking = this.defaultRanking;
      } else {
        this.kpiTableDataSource.data[sortArg].ranking = i + 1;
      }
    });
  }

  /**
   * Updates subscriptions
   * @param subscriptions
   */
  async updateSubscriptions() {
    let siteSubscriptions = (
      await this.siteSubscriptionService.list({
        user: this.authService.loggedInUser.user_id,
      })
    ).results;

    if (this.kpiTableDataSource?.data) {
      this.kpiTableDataSource.data.forEach((row) => {
        row.subscribed =
          siteSubscriptions.find((e) => e.site === row.site.id) !== undefined;
      });
    }
  }

  exportAsExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    ); //converts a DOM TABLE element to a worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    ws['!cols'] = [];
    ws['!cols'][0] = { hidden: true }; // Hide subscribed
    ws['!cols'][3] = { hidden: true }; // Hide flag

    XLSX.utils.book_append_sheet(wb, ws, 'kpi');

    let fromDate = `${this.startDate.getFullYear()}-${this.startDate.getMonth()}-${this.startDate.getDate()}`;
    let toDate = `${this.endDate.getFullYear()}-${this.endDate.getMonth()}-${this.endDate.getDate()}`;

    /* save to file */
    XLSX.writeFile(wb, `skaiwatch kpi from ${fromDate} to ${toDate}.xlsx`);
  }
}
