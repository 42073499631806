import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { OilReport } from '../backend-api/oil-report/oil-report';
import { OilReportService } from '../backend-api/oil-report/oil-report.service';
import { Site } from '../backend-api/site/site';
import { SiteService } from '../backend-api/site/site.service';
import { VibrationReport } from '../backend-api/vibration-report/vibration-report';
import { VibrationReportVerboseService } from '../backend-api/vibration-report/vibration-report-verbose.service';
import { SiteDashboardService } from './site-dashboard.service';
import { SiteMachineListSelected } from './site-machine-list/site-machine-list.component';
import { MachineReportOverviewInput } from './machine-report-overview/machine-report-overview.component';

@Component({
  selector: 'app-dashboard-grid',
  templateUrl: './site-dashboard.component.html',
  styleUrls: ['./site-dashboard.component.scss'],
})
// ----------------------------------------------------
// 				Main class
// ----------------------------------------------------
export class SiteDashboardComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();
  site: Site;
  machineReportOverviewInput: MachineReportOverviewInput;
  machineListIsLoading = true;
  isFetchingReports: boolean;

  constructor(
    private route: ActivatedRoute,
    private siteService: SiteService,
    private siteDashboardService: SiteDashboardService,
    private vibrationReportVerboseService: VibrationReportVerboseService,
    private oilReportService: OilReportService
  ) {}

  ngOnInit() {
    this.route.params
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(async (routerParameters) => {
        this.site = await this.getSite(+routerParameters['siteId']);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  get loading() {
    return this.siteDashboardService.loading;
  }

  async updateReports(event: SiteMachineListSelected) {
    this.isFetchingReports = true;
    let newMachineReportOverviewInput: MachineReportOverviewInput = {};
    newMachineReportOverviewInput.machineName = event.machineName;
    newMachineReportOverviewInput.machineId = event.machineId;
    newMachineReportOverviewInput.machineSfi = event.machineSfi;
    newMachineReportOverviewInput.siteId = this.site.id;

    if (event.latestVibrationReportId) {
      let newVibrationReport =
        await this.vibrationReportVerboseService.retrieve(
          event.latestVibrationReportId
        );
      newMachineReportOverviewInput.latestVibrationReport = new VibrationReport(
        newVibrationReport
      );
    }
    if (event.latestOilReportId) {
      let newOilReport = await this.oilReportService.retrieve(
        event.latestOilReportId
      );
      newMachineReportOverviewInput.latestOilReport = new OilReport(
        newOilReport
      );
    }

    this.machineReportOverviewInput = newMachineReportOverviewInput;
    this.isFetchingReports = false;
  }

  private async getSite(siteId: number): Promise<Site> {
    this.loading.site = true;

    try {
      let siteResponse = await this.siteService.retrieve(siteId);
      this.loading.site = false;
      return new Site(siteResponse);
    } catch (error) {
      console.error(error);
      this.loading.site = false;
    }
  }
}
