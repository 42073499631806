<div class="machine-list-main-container">
  <div *ngIf="mainMachinesTableDataSource?.data?.length > 0">

    <div id="machine-header-container">
      <div mat-subheader id="machines-title">
        MACHINES
      </div>

      <mat-form-field class="machine-group-selector" appearance="fill" *ngIf="machineGroups?.length > 1">
        <mat-label>Select machine group</mat-label>
        <mat-select>
          <mat-option (onSelectionChange)="selectMachineGroup(item, $event)" *ngFor="let item of machineGroups"
            [value]="item">
            {{ item }}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>

    <table mat-table [dataSource]="mainMachinesTableDataSource" class="w-100" matSort #sortMain="matSort">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let machine" style="height: 100%; align-items: center">
          <div style="display: flex; align-items: center">
            <mat-icon class="machine-icon mr-2 machine-category-icon" svgIcon="{{ machine.category }}"
              [ngClass]="machine.machineIconColorClass">
            </mat-icon>
            <div style="display: flex; flex-direction: column; width: 100%">
              <!-- If machine group show group and sfi at top -->
              <div *ngIf="machine.group">
                <div class="machine-group-sfi">
                  <div>
                    {{ machine.group }}
                  </div>
                  <div style="margin: 0 5px">{{ machine.machineSfi }}</div>
                </div>
                <div>
                  {{ machine.name }}
                </div>
              </div>
              <!-- If not machine group show sfi right of name aligned -->
              <div *ngIf="!machine.group" style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                ">
                <div>
                  {{ machine.name }}
                </div>
                <div style="margin: 0 5px" class="font-10-gray">
                  {{ machine.machineSfi }}
                </div>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="border-right: solid #555 1px; width: 160px">
          <div style="margin-left: 60px">Status</div>
        </th>
        <td mat-cell *matCellDef="let machine" style="width: 160px; height: 46px">
          <div style="display: flex; align-items: center">
            <div #machineIconContainer class="machine-icon-container status-nm" style="margin-left: auto">
              <mat-icon [matTooltip]="
                  machine.vibrationIsMaintenancePeriod
                    ? 'Maintenance period'
                    : 'Not measured'
                " *ngIf="machine.vibrationNotMeasured" style="font-size: 25px">
                graphic_eq
              </mat-icon>
            </div>
            <div #machineIconContainer class="machine-icon-container" [ngClass]="machine.vibrationStatusColorClass">
              <mat-icon matTooltip="{{ machine.vibrationStatusString }}" style="font-size: 25px">graphic_eq</mat-icon>
            </div>
            <div #machineIconContainer class="machine-icon-container" [ngClass]="machine.oilStatusColorClass">
              <mat-icon matTooltip="{{ machine.oilStatusString }}" svgIcon="oil_report">
              </mat-icon>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let machine; columns: displayedColumns" class="table-row" style="border-right: solid #444"
        [ngClass]="{ 'selected-row': selectedMachineId == machine.machineId }" (click)="selectMachine(machine)"
        [hidden]="!machine.display"></tr>
    </table>
  </div>
</div>
