<div style="padding: 3rem;">
  <div class="row">
    <div class="col-12 mb-4 site-header" style="height: 100px;">
      <div class="title">
        <h2>Place order for new spare parts</h2>
      </div>
    </div>
  </div>

  <div class="row" style="height: 1rem;"></div>

  <div class="row">
    <table mat-table [dataSource]="dataSource" matSort style="width: 100%;">


      <ng-container matColumnDef="picture">
        <th width="200px" mat-header-cell *matHeaderCellDef mat-sort-header> </th>
        <td mat-cell *matCellDef="let spareParts"> <img style="width: 6em;"
            src="assets/img/bearings/{{spareParts.picture}}.png"> </td>
      </ng-container>

      <ng-container matColumnDef="site">
        <th width="150px" mat-header-cell *matHeaderCellDef mat-sort-header> Vessel </th>
        <td mat-cell *matCellDef="let spareParts"> {{spareParts.site}} </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th width="150px" mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
        <td mat-cell *matCellDef="let spareParts" style="width: 100%;"> {{spareParts.description}}:
          {{spareParts.type}}–{{spareParts.provider}} </td>
      </ng-container>

      <ng-container matColumnDef="price">
        <th width="150px" mat-header-cell *matHeaderCellDef mat-sort-header style="width: 3em;"> Price </th>
        <td mat-cell *matCellDef="let spareParts"> {{spareParts.price}} </td>
      </ng-container>

      <ng-container matColumnDef="order">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
        <td mat-cell *matCellDef="let spareParts let i = index" style="text-align: center;">
          <button mat-flat-button color="primary" (click)="orderPart(i)"
            [disabled]="spareParts.ordered">Place&nbsp;order</button>
        </td>
      </ng-container>

      <ng-container matColumnDef="ordered">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 3em;"> Sent&nbsp;for&nbsp;requisition </th>
        <td mat-cell *matCellDef="let spareParts" style="text-align: center;"> <span *ngIf="spareParts.ordered">
            <mat-icon>check_circle_outline</mat-icon>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
