import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LRMixin } from '../api-mixins';
import { LRCache } from '../api-cache';
import { MachineInterface } from './machine';
import { environment } from 'src/environments/environment';
import { PaginatedListResponse } from '../api-mixins';

@Injectable({
  providedIn: 'root',
})
export class MachineService extends LRMixin<MachineInterface> {
  protected modelBaseUrl: string;

  protected lrCache = new LRCache(5 * 60 * 1000);

  constructor(protected http: HttpClient) {
    super(environment.BACKEND_BASE_URL + 'machine/', http);
  }

  list(
    filter?: { [id: string]: string | number | boolean | string[] | number[] },
    search?: string,
    ordering?: string,
    pageSize?: number,
    customUrl?: string
  ): Promise<PaginatedListResponse<MachineInterface>> {
    // Force `main: True` in filter
    filter ? (filter = { ...filter, main: true }) : (filter = { main: true });

    return super.list(filter, search, ordering, pageSize, customUrl);
  }
}
