<div style="padding: 3rem;">
  <div class="row">
    <div class="col-12 mb-4 site-header" style="height: 100px;">
      <div class="title">
        <h2>Instruments</h2>
      </div>
    </div>
  </div>

  <div class="row" style="height: 1rem;"></div>

  <div class="row">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="nextInspectionDate" matSortDirection="asc" style="width: 100%;">

      <ng-container matColumnDef="hardwareIcon">
        <th width="140px" mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
        <td mat-cell *matCellDef="let instrument">
          <img style="height:70px" src="/assets/img/instrument-thumbnails/{{ instrument.hardwareIcon }}.jpg">
        </td>
      </ng-container>

      <ng-container matColumnDef="site">
        <th width="180px" mat-header-cell *matHeaderCellDef mat-sort-header> Site </th>
        <td mat-cell *matCellDef="let instrument"> {{ instrument.site }} </td>
      </ng-container>

      <ng-container matColumnDef="serialNumber">
        <th width="150px" mat-header-cell *matHeaderCellDef mat-sort-header> Serial number </th>
        <td mat-cell *matCellDef="let instrument"> {{ instrument.serialNumber }} </td>
      </ng-container>

      <ng-container matColumnDef="hardware">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Hardware </th>
        <td mat-cell *matCellDef="let instrument"> {{ instrument.hardware }} </td>
      </ng-container>

      <ng-container matColumnDef="lastInspectionDate">
        <th width="130px" mat-header-cell *matHeaderCellDef mat-sort-header> Last inspection </th>
        <td mat-cell *matCellDef="let instrument"> {{ instrument.lastInspectionDate | date: 'yyyy-MM-dd' }} </td>
      </ng-container>

      <ng-container matColumnDef="nextInspectionDate">
        <th width="130px" mat-header-cell *matHeaderCellDef mat-sort-header> Next inspection </th>
        <td mat-cell *matCellDef="let instrument">
          <span [class.overdue-date]="isDateOverdue(instrument.nextInspectionDate)">
            {{ instrument.nextInspectionDate | date: 'yyyy-MM-dd' }}
            <span *ngIf="isDateOverdue(instrument.nextInspectionDate)">!</span>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
