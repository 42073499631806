import { BlobStorageUploadParameters } from '../_services/azure-storage/azure-storage';
import { BlobStorageService } from '../_services/azure-storage/azure-storage.service';

export class UploadObject<DatabaseType> {
  databaseInstance: DatabaseType = null;
  uploadProgress = null;
  createError = false;
  uploadError = false;
  uploadFinishedError = false;
  isCreatingSourceFile = false;
  isFinishingUpload = false;
  isFinished = false;

  databaseCreatePromise: Promise<DatabaseType>;

  constructor(
    public file: File,
    private databaseService: any,
    public _uploadAbortController: AbortController = null
  ) {}

  async create(createData?: Partial<DatabaseType>): Promise<void> {
    this.isCreatingSourceFile = true;
    this.databaseCreatePromise = this.databaseService.create(
      this.file,
      createData
    );
    await this.awaitCreatePromise();

    this.isCreatingSourceFile = false;
  }

  private async awaitCreatePromise() {
    try {
      this.databaseInstance = await this.databaseCreatePromise;
    } catch (error) {
      console.warn(error);
      this.createError = true;
    }
  }

  isUploading() {
    if (this.uploadProgress === null) return false;
    return (
      this.uploadProgress !== 100 &&
      !this.isCreatingSourceFile &&
      (!this.createError || this.uploadError)
    );
  }

  isError() {
    return this.createError || this.uploadError || this.uploadFinishedError;
  }
}

export class FileUploader<DatabaseType> {
  constructor(
    protected databaseService: any,
    protected blobStorageService: BlobStorageService
  ) {}

  createUploadObject(file: File): UploadObject<DatabaseType> {
    return new UploadObject<DatabaseType>(file, this.databaseService);
  }

  async startUpload(
    uploadObject: UploadObject<DatabaseType>,
    createData?: Partial<DatabaseType>
  ) {
    await uploadObject.create(createData);
    this.afterCreateUpload(uploadObject);
  }

  async fixErrors(uploadObject: UploadObject<DatabaseType>) {
    if (uploadObject.createError) {
      uploadObject.createError = false;
      await uploadObject.create();
      this.afterCreateUpload(uploadObject);
    }
    if (uploadObject.uploadError) {
      uploadObject.uploadProgress = null;
      uploadObject.uploadError = false;
      this.afterCreateUpload(uploadObject);
    }
    if (uploadObject.uploadFinishedError) {
      uploadObject.uploadFinishedError = false;
      this.afterUploadFinished(uploadObject);
    }
  }

  private afterCreateUpload(uploadObject: UploadObject<DatabaseType>) {
    if (uploadObject.createError) return;

    let uploadParameters = this.uploadToBlob(
      uploadObject.file,
      uploadObject.databaseInstance['file']
    );
    uploadObject._uploadAbortController =
      uploadParameters.uploadAbortController;

    uploadParameters.uploadProgressObservable.subscribe(
      (event) => {
        uploadObject.uploadProgress = Math.round(
          (event / uploadObject.file.size) * 100
        );
        if (!uploadObject.isError() && uploadObject.uploadProgress === 100) {
          this.afterUploadFinished(uploadObject);
        }
      },
      (error) => {
        console.error(error);
        uploadObject.uploadError = true;
        // Delete file if any error occurs.
        this.destroyUpload(uploadObject);
      }
    );
  }

  /**
   * Set is_file_empty to false in the database.
   * @param uploadObject Upload object
   */
  private async afterUploadFinished(uploadObject: UploadObject<DatabaseType>) {
    uploadObject.isFinishingUpload = true;
    try {
      await this.databaseService.update(uploadObject.databaseInstance['id'], {
        is_file_empty: false,
      });
      uploadObject.isFinished = true;
    } catch (error) {
      console.error(error);
      uploadObject.uploadFinishedError = true;
    }

    uploadObject.isFinishingUpload = false;
  }

  async destroyUpload(
    uploadObject: UploadObject<DatabaseType>
  ): Promise<boolean> {
    // First cancel file upload request if any
    this.cancelFileUploadRequest(uploadObject);

    if (uploadObject.createError) {
      // if create error, there is nothing to delete. Successful destroy.
      return true;
    }

    let databaseInstance: DatabaseType = uploadObject.databaseInstance;

    // If upload object is creating database instance
    if (uploadObject.isCreatingSourceFile) {
      try {
        // Wait for create to finish
        databaseInstance = await uploadObject.databaseCreatePromise;
      } catch (error) {
        // if create error, there is nothing to delete. Successful destroy.
        return true;
      }
    }

    // If source file has already been destroyed (is null)
    if (!databaseInstance) return true;

    try {
      await this.databaseService.destroy(databaseInstance['id']);
      uploadObject.databaseInstance = null;
      // Successful destroy
      return true;
    } catch (error) {
      console.error(error);
      // Unsuccessful destroy
      return false;
    }
  }

  private cancelFileUploadRequest(uploadObject: UploadObject<DatabaseType>) {
    if (uploadObject._uploadAbortController) {
      uploadObject._uploadAbortController.abort();
      // Remove abort controller to prevent sending another abort signal.
      uploadObject._uploadAbortController = null;
      uploadObject.uploadProgress = 0;
    }
  }

  private uploadToBlob(file: File, url: string): BlobStorageUploadParameters {
    let [urlNoToken, sasToken] = url.split('?');
    let urlNoTokenSplit = urlNoToken.split('/');
    let containerName = urlNoTokenSplit[urlNoTokenSplit.length - 2];
    let filename = urlNoTokenSplit[urlNoTokenSplit.length - 1];
    let uri = urlNoTokenSplit.slice(0, urlNoTokenSplit.length - 2).join('/');

    return this.blobStorageService.uploadToBlobStorage(file, {
      containerName: containerName,
      filename: filename,
      storageUri: uri,
      storageAccessToken: sasToken,
    });
  }
}
