import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import {
  ApiListParameters,
  ApiListController,
} from '../../../backend-api/api-list-controller';
import { MachineInterface } from '../../../backend-api/machine/machine';
import { SiteInterface } from '../../../backend-api/site/site';
import { getVibrationReportSeverityLevelLabelClass } from '../../../backend-api/vibration-report/tools';
import {
  VibrationReportFilterParameters,
  VibrationReportInterface,
} from '../../../backend-api/vibration-report/vibration-report';
import { VibrationReportService } from '../../../backend-api/vibration-report/vibration-report.service';
import { VibrationReportDialogComponent } from '../../../dialogs/vibration-report-dialog/vibration-report-dialog.component';
import { FilterParameters } from '../report-archive.component';

/// <reference path="../../../backend-api/vibration-report/vibration-report.ts" />

interface ReportTableRow {
  id: number;
  dateApproved: Date;
  status: string;
  statusLabelClass: string;
  notMeasured: boolean;
  isMaintenancePeriod: boolean;
  isDownloading: boolean;
  siteName: string;
  siteId: number;
  machineName: string;
  machineGroup: string;
  machineId: number;
  severityLevel: string[];
}

@Component({
  selector: 'app-vibration-report-archive-table',
  templateUrl: './vibration-report-archive-table.component.html',
  styleUrls: ['./vibration-report-archive-table.component.scss'],
})
export class VibrationReportArchiveTableComponent implements OnInit, OnChanges {
  @Input() filterParameters: FilterParameters;

  @Input() selectedMachine: MachineInterface;
  @Input() selectedSite: SiteInterface;

  @Output() internalSiteToggle = new EventEmitter<SiteInterface>();
  @Output() internalMachineToggle = new EventEmitter<MachineInterface>();

  @ViewChild('reportPaginator') reportPaginator: MatPaginator;

  reportTableDataSource = new MatTableDataSource<ReportTableRow>();
  reportApiFilterForm: FormGroup;
  reportPaginationSize = 50;
  reportApiListController: ApiListController<
    VibrationReportInterface,
    VibrationReportFilterParameters
  >;
  destroy$ = new Subject<void>();
  displayedColumns: string[] = [
    'siteName',
    'machineName',
    'dateApproved',
    'status',
    'severity',
    'buttons',
  ];

  private currentReportFilter = {};
  private currentReportSearch = '';

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private reportService: VibrationReportService
  ) {}

  initDone = false;
  async ngOnInit(): Promise<void> {
    this.initReportForm();
    this.initApiListController();

    this.reportApiListController.responseData
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.reportTableDataSource = new MatTableDataSource(
          data.map((r) => this.report2ReportTableRow(r))
        );
      });

    this.applyReportApiSearchOnChanges();

    await this.loadInitialData();
    this.initDone = true;
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  ngOnChanges(changes) {
    if (this.initDone) {
      this.reportFilterOnFormChanges(this.filterParameters);
    }
  }

  filterParametersToVibrationReportApiParameters(
    filterParameters: FilterParameters
  ): ApiListParameters<VibrationReportFilterParameters> {
    let reportApiParameters = {};

    if (filterParameters.siteGroupFamily)
      reportApiParameters['site__site_group__site_group_family'] =
        filterParameters.siteGroupFamily;
    if (filterParameters.siteGroup)
      reportApiParameters['site__site_group'] = filterParameters.siteGroup;
    if (filterParameters.site)
      reportApiParameters['site'] = filterParameters.site;
    if (filterParameters.machine)
      reportApiParameters['machine'] = filterParameters.machine;

    return reportApiParameters;
  }

  /**
   * Loads site group families, and loads first page for sites, machines and reports.
   *  Select machine or site if url parameters ('machine' or 'site') are provided.
   */
  private async loadInitialData(): Promise<void> {
    this.reportApiListController.isLoading = true;
    this.reportApiListController.changePage(1);
  }

  private initReportForm() {
    this.reportApiFilterForm = this.fb.group({
      reportSearch: '',
    });
  }

  private initApiListController() {
    let baseApiEndpointParameters: ApiListParameters<VibrationReportFilterParameters> =
      { fieldsToExtend: ['site', 'machine'], setsToAdd: ['bearingstatus_set'] };

    this.reportApiListController = new ApiListController<
      VibrationReportInterface,
      VibrationReportFilterParameters
    >(this.reportPaginationSize, this.reportService, baseApiEndpointParameters);
  }

  private report2ReportTableRow(
    report: VibrationReportInterface
  ): ReportTableRow {
    return {
      id: report.id,
      status: report.severity_level,
      statusLabelClass: getVibrationReportSeverityLevelLabelClass(report),
      notMeasured: report.no_new_measurement,
      isMaintenancePeriod: report.is_maintenance_period,
      dateApproved: new Date(report.date_approved),
      isDownloading: false,
      siteName: (report.site as SiteInterface).name,
      siteId: (report.site as SiteInterface).id,
      machineName: (report.machine as MachineInterface).name,
      machineGroup: (report.machine as MachineInterface).group,
      machineId: (report.machine as MachineInterface).id,
      severityLevel: this.findBearingStatuses(report.bearing_statuses),
    };
  }

  private findBearingStatuses(bearing_statuses: any) {
    let severity = [];
    bearing_statuses.forEach((element) => {
      if (element.bearing_severity_level)
        severity.push(element.bearing_severity_level.toUpperCase());
    });
    return severity;
  }

  private applyReportApiSearchOnChanges() {
    this.reportApiFilterForm.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(500))
      .subscribe((changes) => {
        if (changes.reportSearch === this.currentReportSearch) return; // skip if changes not from report search bar.

        this.currentReportSearch = changes.reportSearch;

        let nextFilter = { ...this.currentReportFilter };
        if (changes.reportSearch.trim())
          nextFilter = { ...nextFilter, search: changes.reportSearch.trim() };

        this.reportApiListController.applyApiFilter(nextFilter);
        this.reportPaginator.firstPage();
      });
  }

  private reportFilterOnFormChanges(filterParameters: FilterParameters) {
    this.currentReportFilter =
      this.filterParametersToVibrationReportApiParameters(filterParameters);

    let nextReportFilter: any = {
      ...this.currentReportFilter,
    };

    if (this.currentReportSearch.trim()) {
      nextReportFilter = {
        ...nextReportFilter,
        search: this.currentReportSearch.trim(),
      };
    }

    this.reportApiListController.applyApiFilter({
      ...nextReportFilter,
    });
    this.reportPaginator.firstPage();
  }

  downloadReport(reportRow: ReportTableRow, event?: MouseEvent): void {
    if (event) event.stopPropagation();

    reportRow.isDownloading = true;
    this.reportService.downloadReportPdf(reportRow.id).then(
      () => {
        reportRow.isDownloading = false;
      },
      (error) => {
        reportRow.isDownloading = false;
      }
    );
  }

  openReportDialog(reportRow: ReportTableRow, event?: MouseEvent) {
    if (event) event.stopPropagation();
    const dialogRef = this.dialog.open(VibrationReportDialogComponent, {
      width: '1050px',
      data: { vibrationReportId: reportRow.id },
      panelClass: ['dialog__no-padding'],
    });
  }

  /**
   * When clicking on a site name in the report table. Converts ReportTableRow to a site object.
   * @param report
   */
  selectSiteFromReportTable(report: ReportTableRow) {
    this.internalSiteToggle.next({ name: report.siteName, id: report.siteId });
  }
  /**
   * When clicking on a machine name in the report table. Converts ReportTableRow to a machine object.
   * @param report
   */
  selectMachineFromReportTable(report: ReportTableRow) {
    this.internalMachineToggle.emit({
      name: report.machineName,
      id: report.machineId,
      group: report.machineGroup,
    });
  }

  deselectMachine() {
    this.internalMachineToggle.emit(null);
  }

  deselectSite() {
    this.internalSiteToggle.emit(null);
  }
}
