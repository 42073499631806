<!-- REPORTS -->
<div class="report-container">
  <!-- Report table header -->
  <div class="archive-container__header">
    <div style="display: flex;">
      <div class="flex-ai-center">
        <h2>Machine status reports</h2>
        <mat-spinner *ngIf="reportApiListController.isLoading" style="margin: 0 15px;" diameter="25"></mat-spinner>
      </div>
      <!-- Report table form -->
      <form [formGroup]="reportApiFilterForm" class="report-table-form">
        <mat-form-field class="report-search-field">
          <!-- Search -->
          <mat-label>Search</mat-label>
          <input matInput type="text" formControlName="reportSearch" placeholder="Eg. analysed by, machine, date" />
          <button type="button" mat-button *ngIf="reportApiFilterForm.get('reportSearch').value" matSuffix
            mat-icon-button aria-label="Clear" (click)="reportApiFilterForm.get('reportSearch').setValue('')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </form>
    </div>
    <!-- Selected site/machine arrows -->
    <div style="display: flex">
      <!-- Selected site -->
      <div *ngIf="selectedSite" class="select-arrow mat-elevation-z2">
        <div class="select-arrow-deselect-icon" (click)="deselectSite()">
          <i class="fal fa-times"></i>
        </div>
        <div class="select-arrow-name">
          {{selectedSite.name}}
        </div>
      </div>
      <!-- Selected machine -->
      <div *ngIf="selectedMachine" class="select-arrow mat-elevation-z2">
        <div class="select-arrow-deselect-icon" (click)="deselectMachine()">
          <i class="fal fa-times"></i>
        </div>

        <div class="select-arrow-machine-group">
          {{selectedMachine.group ? selectedMachine.group + '&nbsp;&nbsp;&nbsp;' : ''}}
        </div>
        <div class="select-arrow-name">
          {{selectedMachine.name}}
        </div>
      </div>
    </div>
  </div>
  <!-- Report table -->
  <div class="archive-container report-table-container">
    <table mat-table [dataSource]="reportTableDataSource" class="archive-container report-table">
      <ng-container matColumnDef="siteName">
        <th mat-header-cell *matHeaderCellDef>Site</th>
        <td mat-cell *matCellDef="let reportRow" class="table-column__site-name">
          <span class="selectable" (click)="selectSiteFromReportTable(reportRow)">{{reportRow.siteName}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="machineName">
        <th mat-header-cell *matHeaderCellDef>Machine</th>
        <td mat-cell *matCellDef="let reportRow" class="table-column__machine-name">
          <div class="font-10-gray">
            {{reportRow.machineGroup}}
          </div>
          <span class="selectable" (click)="selectMachineFromReportTable(reportRow)">
            {{reportRow.machineName}}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="dateApproved">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let reportRow" class="table-column__date">
          {{reportRow.dateApproved | date: 'dd MMM yyyy, HH:mm'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef style="text-align:center;">Status</th>
        <td mat-cell *matCellDef="let reportRow" class="table-column__status">
          <span *ngIf="reportRow.notMeasured" class="app-label__disabled status-not-measured-margins"
            [matTooltip]="reportRow.isMaintenancePeriod ? 'Maintenance period' : 'Not measured'">
            {{ reportRow.isMaintenancePeriod ? 'MP' : 'NM'}}
          </span>

          <span [ngClass]="reportRow.statusLabelClass" style="width: min-content;">
            {{reportRow.status}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="severity">
        <th mat-header-cell *matHeaderCellDef>Severity</th>
        <td mat-cell *matCellDef="let reportRow" class="table-column__site-name">
          <span style="margin-left: 30px;">{{reportRow.severityLevel}}</span>
          <span *ngIf="reportRow.severityLevel.length < 1">–</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="buttons">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let reportRow" class="table-column__buttons">
          <div style="display: flex;">
            <i style="margin-right: 15px;" class="fal fa-file-alt icon-button" matTooltip="Open report"
              matTooltipPosition="below" (click)="openReportDialog(reportRow, $event)"></i>
            <i *ngIf="!reportRow.isDownloading else loadingSpinner" class="fal fa-download icon-button"
              matTooltip="Download PDF" matTooltipPosition="below" (click)="downloadReport(reportRow, $event)"></i>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let reportRow; columns: displayedColumns;" class="selectable-row"
        (click)="openReportDialog(reportRow)"></tr>
    </table>
  </div>

  <!-- Report paginator -->
  <mat-paginator #reportPaginator [length]="reportApiListController.dataTotal"
    [pageSize]="reportApiListController.pageSize" (page)="reportApiListController.changePage($event.pageIndex + 1)">
  </mat-paginator>
</div>


<ng-template #loadingSpinner>
  <mat-spinner diameter="20"></mat-spinner>
</ng-template>
