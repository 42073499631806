import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './_services/guard/authenticate/auth.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReportComponent } from './report/report.component';
import { SiteDashboardComponent } from './site-dashboard/site-dashboard.component';
import { LoginLayoutComponent } from './_layouts/login-layout.component';
import { HomeLayoutComponent } from './_layouts/home-layout.component';
import { ProfileComponent } from './profile/profile.component';
import { SiteManagementComponent } from './site-management/site-management.component';
import { AccountComponent } from './account/account.component';
import { ArchiveComponent } from './archive/archive.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SiteArchiveComponent } from './site-archive/site-archive.component';
import { SupportComponent } from './support/support.component';
import { OilReportViewComponent } from './oil-report-view/oil-report-view.component';
import { UploadSourceFilesComponent } from './upload-source-files/upload-source-files.component';
import { UploadSourceFilesComponentCanDeactivateGuardService } from './_services/guard/deactivate/source-file-upload-deactivate.guard';
import { MaintenancePlannerComponent } from './maintenance-planner/maintenance-planner.component';
import { SparePartsComponent } from './spare-parts/spare-parts.component';
import { InstrumentsComponent } from './instruments/instruments.component';
import { KpiComponent } from './kpi/kpi.component';

const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: DashboardComponent, canActivate: [AuthGuard] },
      {
        path: 'sites/:siteId',
        component: SiteDashboardComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'archive',
            component: SiteArchiveComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'report/:report_id',
        component: ReportComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'support',
        component: SupportComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'instruments',
        component: InstrumentsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'fleet-management',
        component: SiteManagementComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'account',
        component: AccountComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'archive',
        component: ArchiveComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'upload',
        component: UploadSourceFilesComponent,
        canActivate: [AuthGuard],
        canDeactivate: [UploadSourceFilesComponentCanDeactivateGuardService],
      },
      {
        path: 'oil',
        component: OilReportViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'maintenance-planner',
        component: MaintenancePlannerComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'spare-parts',
        component: SparePartsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'kpi',
        component: KpiComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
      { path: '404', component: PageNotFoundComponent },
    ],
  },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
