// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const current_domain = window.location.hostname; // E.g. portal.skaiwatch.no
const backend_domain = current_domain.replace('portal', 'backend'); // E.g. backend.skaiwatch.no

export const environment = {
  production: true,
  BACKEND_BASE_URL: `https://${backend_domain}/api/v1/`,

  // Microsoft login for DOF
  MSAL_CLIENT_ID: '35fab283-62c9-4bd1-8638-8b81aacca607',
  MSAL_AUTHORITY:
    'https://login.microsoftonline.com/d5e85ffc-d9d8-4aed-a3d7-8c39e1b52167/',
  MSAL_REDIRECT_URL: `https://${current_domain}/login`,
};
