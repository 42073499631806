<div class="dashboard-container">
  <!---------------------------------------------------------------------->
  <!--                    Header                    									  -->
  <!---------------------------------------------------------------------->

  <div class="header">
    <img *ngIf="user.company_logo_dark_bg" class="customer-company-logo" [src]="user.company_logo_dark_bg" />
  </div>

  <div class="info-container fst-row-height">
    <div class="site-overview">
      <!-------------------------------------------------->
      <!--         Site table					                  -->
      <!-------------------------------------------------->

      <div class="site-table">
        <div style="display: flex; align-items: center;">
          <mat-form-field class="search-field">
            <mat-label>
              <p class="fa fa-search"></p>
              Search
            </mat-label>
            <input matInput (keyup)="onSearchBoxChange($event)" placeholder="E.g. vessel name or type">
          </mat-form-field>

          <mat-spinner style="margin-left: auto; margin-right: 2em;" *ngIf="!selectedSite" diameter=40>
          </mat-spinner>

        </div>
        <div style="height: calc(100% - 150px); overflow: auto;">
          <table mat-table mat-list-item [dataSource]="siteTableDataSource" matSort matSortActive="name"
            matSortDirection="asc" style="width: 100%;" class="ship-table">
            <!-- Subscribe Column -->
            <ng-container matColumnDef="subscribed">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Subscribe </th>
              <td mat-cell *matCellDef="let site">
                <app-site-subscribe [isSubscribed]="site.subscribed" [siteId]="site.id"></app-site-subscribe>
              </td>
            </ng-container>
            <!-- Company Column -->
            <ng-container matColumnDef="company">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Company </th>
              <td mat-cell *matCellDef="let site">
                <span>
                  {{site.company_name}}
                </span>
              </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
              <td mat-cell *matCellDef="let site">
                <span>
                  {{site.name}}
                </span>
              </td>
            </ng-container>
            <!-- Site group family Column -->
            <ng-container matColumnDef="site_group_family">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Fleet </th>
              <td mat-cell *matCellDef="let site">
                {{site.site_group_detail ? site.site_group_detail.site_group_family_detail.name : 'N/A'
                }}<ng-container *ngIf="site.site_group_detail">: {{ site.site_group_detail.name }}
                </ng-container>
              </td>
            </ng-container>

            <!-- Country Column -->
            <ng-container matColumnDef="country">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Flag </th>
              <td mat-cell *matCellDef="let site">
                <span class="flag-icon" [ngClass]="'flag-icon-' + site.country.toLowerCase()"
                  title="{{site.country}}"></span>
              </td>
            </ng-container>

            <!-- Site type Column -->
            <ng-container matColumnDef="site_type">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Vessel type
              </th>
              <td mat-cell *matCellDef="let site">
                <span>
                  {{site.site_type}}
                </span>
              </td>
            </ng-container>

            <!-- Date modified Column -->
            <ng-container matColumnDef="latestReportDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header start="desc"> Latest report </th>
              <td mat-cell *matCellDef="let site">

                <mat-spinner *ngIf="site.nGoodVibReports === null" diameter="25">
                </mat-spinner>

                <span *ngIf="site.nGoodVibReports !==null && site.latestReportDate">
                  {{site.latestReportDate | date: "dd MMM yyyy HH:mm" }}
                </span>
                <span *ngIf="site.nGoodVibReports !==null && !site.latestReportDate" class="no-data"
                  matTooltip="No reports">
                  –
                </span>
              </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>
                Status </th>
              <td mat-cell *matCellDef="let site">
                <span class="label__alarm" matTooltip="Machines with status: Alarm"
                  [class.zero]="site.nAlarmVibReports==0">{{site.nAlarmVibReports}}</span>
                <span class="label__warning" matTooltip="Machines with status: Warning"
                  [class.zero]="site.nWarningVibReports==0">{{site.nWarningVibReports}}</span>
                <span class="label__good" matTooltip="Machines with status: Good"
                  [class.zero]="site.nGoodVibReports==0">{{site.nGoodVibReports}}</span>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="siteTableColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let site; columns: siteTableColumns;" class="table-row" (click)="goToSite(site.id)"
              (mouseenter)="selectSite(site)">
            </tr>
          </table>
        </div>

        <mat-paginator [pageSize]="100" hidePageSize showFirstLastButtons></mat-paginator>
      </div>

      <!-------------------------------------------------->
      <!--              Ship Info			                  -->
      <!-------------------------------------------------->
      <div id="ship-info">
        <div class="info-container h-100">
          <mat-toolbar-row class="mat-tab-header">
            <span>Ship info</span>
          </mat-toolbar-row>
          <div *ngIf="selectedSite" style="margin:1.5rem;">
            <ng-container *ngIf="selectedSite.img_url; else noImg">
              <div class="site-img" [ngStyle]="{backgroundImage: 'url('+selectedSite.img_url+')'}"></div>
            </ng-container>
            <ng-template #noImg>
              <div class="site-img" [ngStyle]="{backgroundImage: 'url(../../assets/img/icon/ship.png)'}"></div>
            </ng-template>

            <table class="info-table th-width-100 w-100 my-4">
              <tr>
                <th>Name</th>
                <td>{{selectedSite.name}}</td>
              </tr>
              <tr>
                <th>IMO</th>
                <td>{{selectedSite.location_identifier || "N/A"}}</td>
              </tr>
              <tr>
                <th>Year built</th>
                <td>{{selectedSite.year_built || "N/A"}}</td>
              </tr>
            </table>

            <!-- TODO, Jon: Fill out -->
            <!-- <table class="info-table th-width-200 w-100 my-4">
          <tr>
            <th>Monitored machines</th>
            <td class="numeric">20</td>
          </tr>
          <tr>
            <th>Total measurement points</th>
            <td class="numeric">112</td>
          </tr>
          <tr>
            <th>Total monitored bearings</th>
            <td class="numeric">85</td>
          </tr>
        </table> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #SiteTableLoading>
  <mat-progress-bar mode="buffer" [bufferValue]="(100/6)*siteTableLoadingProgress.value"></mat-progress-bar>
</ng-template>

<ng-template #loadingSpinner>
  <mat-progress-spinner mode="indeterminate" diameter="100"></mat-progress-spinner>
</ng-template>
