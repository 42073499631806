  <div class="site-dashboard-container">
    <!---------------------------------------------------------------------->
    <!--                    Header                    									  -->
    <!---------------------------------------------------------------------->
    <div class="container-fluid row">
      <div class="col-12 site-header" style="height: 142px; align-items: center;">
        <button mat-button [routerLink]="['']">
          <i class="fas fa-chevron-left back-icon"></i> Back
        </button>
        <div class="title"
         >
          <h2 class="site-title mb-2">
            {{site ? site.name : ""}}
            <app-site-subscribe *ngIf="site" [siteId]="site?.id">
            </app-site-subscribe>
          </h2>
        </div>
      </div>
    </div>

    <div style="background:#393939;">
      <div class="map-and-machine-group-summary">

        <div *ngIf="site && site.graphic" class="site-map">
          <app-site-map [site]="site">
          </app-site-map>
        </div>
        <div class="machine-status-overview" *ngIf="site">
          <app-machine-group-status-summary [site]="site"></app-machine-group-status-summary>
        </div>
      </div>

      <div *ngIf="machineListIsLoading" class="loading-spinner">
        <mat-spinner diameter="100"></mat-spinner>
      </div>

      <div style="display: flex;">
        <div class="machine-list"
         >
          <app-site-machine-list *ngIf="site" [site]="site" (selected)="updateReports($event)"
            (isLoading)="machineListIsLoading=$event"></app-site-machine-list>
        </div>
        <div class="report-view-container">
          <div *ngIf="isFetchingReports" style="margin-top:100px">
            <mat-spinner diameter="100"></mat-spinner>
          </div>
          <app-machine-report-overview *ngIf="!isFetchingReports" class="report-view"
            [input]="machineReportOverviewInput" [ngClass]="{'display-none':isFetchingReports}"
           >
          </app-machine-report-overview>
        </div>
      </div>
    </div>
  </div>
