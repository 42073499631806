<div class="container selectable-text light-theme">
  <div class="report-container" *ngIf="reportVerbose">
    <!---------------------------------------------------------------------->
    <!--                    Header                     									  -->
    <!---------------------------------------------------------------------->
    <div class="header-container">
      <div style="width:160px;"><img class="customer-logo" src="{{reportVerbose.company_logo}}"
          alt="{{reportVerbose.company_name}}"></div>
      <div style="text-align: center;">Report {{reportVerbose.id}}</div>
      <div style="width:160px; text-align:right;"><img class="km-logo" src="{{kmLogoURL}}" alt="Karsten Moholt">
      </div>
    </div>

    <!---------------------------------------------------------------------->
    <!--                    Title                     									  -->
    <!---------------------------------------------------------------------->
    <div class="title-container">
      <div class="site">
        <div>
          {{getSiteNameWithMachineGroups()}}&nbsp;
          <span class="imo" *ngIf="reportVerbose.site_location_identifier">IMO:&nbsp;
            {{reportVerbose.site_location_identifier}}
          </span>
        </div>
      </div>

      <div class="flex-direction-column">
        <div *ngIf="reportVerbose.no_new_measurement" class="last-status-text">LAST STATUS</div>
        <div class="machine-status-container">
          <div>
            <div class="icon-title-container">
              <div #machineIconContainer class="machine-icon-container" [ngClass]="statusColorClass">
                <!-- A svg dom element is inserted here at runtime by updateMachineIcon() -->
              </div>
              <div>
                <div class="machine-name">{{reportVerbose.machine_name}}
                </div>
                <div class="sfi" *ngIf="reportVerbose.machine_SFI_number">SFI/TAG&nbsp;&nbsp;
                  {{reportVerbose.machine_SFI_number}}</div>
              </div>
            </div>
          </div>

          <div class="status not-measured" *ngIf="reportVerbose.no_new_measurement">
            <div *ngIf="!reportVerbose.is_maintenance_period">
              NOT MEASURED
            </div>
            <div *ngIf="reportVerbose.is_maintenance_period">
              MAINTENANCE PERIOD
            </div>
          </div>
          <div>
            <div class="status" [ngClass]="statusColorClass">
              {{reportVerbose.severity_level?.toUpperCase()}}
            </div>
            <div class="report-date">{{reportDate | date: "d MMMM yyyy"}}</div>
          </div>

        </div>
      </div>
      <div class="color-bar" [ngClass]="statusColorClass"></div>
    </div>


    <!---------------------------------------------------------------------->
    <!--                    Main section               									  -->
    <!---------------------------------------------------------------------->
    <div class="main-container">
      <div class="view-mode">
        <!-- Machine status -->
        <div *ngIf="doesReportHaveStatuses()">
          <table class="display-table">
            <thead>
              <tr>
                <th style="width: 11ch;">Type</th>
                <th style="width: 23ch;">Status</th>
                <th>Description</th>
                <th style="width: 17ch;">Fault</th>
                <th style="width: 17ch;">Severity level</th>
                <th style="width: 17ch;">Bearing type</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let machineStatus of reportVerbose.machine_statuses; let i = index">
                <td>Machine</td>
                <td>
                  <span [ngClass]="{
                            'label__neutral': machineStatus.status === 'None' || machineStatus.status === 'Incomplete Data',
                            'label__good': machineStatus.status === 'Good' || machineStatus.status ==='Acceptance Report',
                            'label__warning': machineStatus.status === 'Warning',
                            'label__alarm': machineStatus.status === 'Alarm'
                      }">
                    {{ machineStatus.status }}
                  </span>
                </td>
                <td>{{machineStatus.comment || '–'}}</td>
                <td>{{machineStatus.defect?.name || '–'}}</td>
                <td>–</td>
                <td>–</td>
              </tr>

              <tr *ngFor="let oilStatus of reportVerbose.oil_statuses; let i = index">
                <td>Oil</td>
                <td>
                  <span [ngClass]="{
                            'label__neutral': oilStatus.status === 'None' || oilStatus.status === 'Incomplete Data',
                            'label__good': oilStatus.status === 'Good' || oilStatus.status ==='Acceptance Report',
                            'label__warning': oilStatus.status === 'Warning',
                            'label__alarm': oilStatus.status === 'Alarm'
                      }">
                    {{ oilStatus.status }}
                  </span>
                </td>
                <td>{{oilStatus.comment || '–'}}</td>
                <td>{{oilStatus.defect?.name || '–'}}</td>
                <td>–</td>
                <td>–</td>
              </tr>

              <tr *ngFor="let bearingStatus of reportVerbose.bearing_statuses; let i = index">
                <td>Bearing</td>
                <td>
                  <span [ngClass]="{
                            'label__neutral': bearingStatus.status === 'None' || bearingStatus.status === 'Incomplete Data',
                            'label__good': bearingStatus.status === 'Good' || bearingStatus.status ==='Acceptance Report',
                            'label__warning': bearingStatus.status === 'Warning',
                            'label__alarm': bearingStatus.status === 'Alarm'
                      }">
                    {{ bearingStatus.status }}
                  </span>
                </td>
                <td>{{bearingStatus.comment || '–'}}</td>
                <td>{{bearingStatus.defect?.name || '–'}}</td>
                <td>{{bearingStatus.bearing_severity_level || '–'}}</td>
                <td>{{bearingStatus.bearing?.manufacturer}}–{{bearingStatus.bearing?.type}}</td>
              </tr>

              <tr *ngFor="let electricalStatus of reportVerbose.electrical_statuses; let i = index">
                <td>Electrical</td>
                <td>
                  <span [ngClass]="{
                            'label__neutral': electricalStatus.status === 'None' || electricalStatus.status === 'Incomplete Data',
                            'label__good': electricalStatus.status === 'Good' || electricalStatus.status ==='Acceptance Report',
                            'label__warning': electricalStatus.status === 'Warning',
                            'label__alarm': electricalStatus.status === 'Alarm'
                      }">
                    {{ electricalStatus.status }}
                  </span>
                </td>
                <td>{{electricalStatus.comment || '–'}}</td>
                <td>{{electricalStatus.defect?.name || '–'}}</td>
                <td>–</td>
                <td>–</td>
              </tr>

            </tbody>
          </table>
          <div style="margin-top: 5px;">
            <span class="confidence-level" *ngIf="reportVerbose.confidence_level">Confidence:&nbsp;</span>
            <span class="confidence-level"
              [ngClass]="reportVerbose.confidence_level.toLowerCase()">{{reportVerbose.confidence_level}}</span>
          </div>
        </div>

        <!-- Recommendation -->
        <div *ngIf="reportVerbose.recommendation">
          <h2>Recommendation</h2>
          <div markdown ngPreserveWhitespaces>
            {{reportVerbose.recommendation}}
          </div>
        </div>

        <!-- Analysis -->
        <div *ngIf="reportVerbose.analysis">
          <h2>Analysis</h2>
          <div markdown ngPreserveWhitespaces>
            {{reportVerbose.analysis}}
          </div>
        </div>

        <!-- Graph images -->
        <div class="images">
          <div *ngFor="let image of reportVerbose.report_images;" class="graph-img">
            <img *ngIf="image.image_as_svg" src="{{ image.image_as_svg }}" (click)="openImage(image.image_as_svg)">
            <img *ngIf="!image.image_as_svg" src="{{ image.image }}" (click)="openImage(image.image)">
          </div>
        </div>


        <h2>Machine operation parameters</h2>
        <div class="machine-parameter-table">
          <table>
            <tbody>
              <tr>
                <td class="footer-tb-head">Running hours</td>
                <td class="footer-tb-td">{{reportVerbose.running_hours ? reportVerbose.running_hours + ' hours' : '–'}}
                </td>
              </tr>
            </tbody>
          </table>

          <table>
            <tbody>
              <tr>
                <td class="footer-tb-head">Speed</td>
                <td class="footer-tb-td">{{reportVerbose.speed_rpm ? reportVerbose.speed_rpm + ' RPM' : '–'}}</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td class="footer-tb-head">Load</td>
                <td class="footer-tb-td">{{reportVerbose.load_kw ? reportVerbose.load_kw + ' kW' : '–'}}</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td class="footer-tb-head">Pitch</td>
                <td class="footer-tb-td">{{reportVerbose.pitch_pct ? reportVerbose.pitch_pct + ' %' : '–'}}</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td class="footer-tb-head">Electrical current</td>
                <td class="footer-tb-td">{{reportVerbose.amps_a ? reportVerbose.amps_a + ' A' : '–'}}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="foot-table-container">
          <table>
            <tbody>
              <tr>
                <td class="footer-tb-head">Customer</td>
                <td class="footer-tb-td">{{reportVerbose.company_name || '–'}}</td>
              </tr>
              <tr>
                <td class="footer-tb-head">PO Number</td>
                <td class="footer-tb-td">–</td>
              </tr>
              <tr>
                <td class="footer-tb-head">SO Number</td>
                <td class="footer-tb-td">{{reportVerbose.so_number || '–'}}</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td class="footer-tb-head">Location</td>
                <td class="footer-tb-td">{{reportVerbose.site_name || '–'}}</td>
              </tr>
              <tr>
                <td class="footer-tb-head">SFI/TAG</td>
                <td class="footer-tb-td">{{reportVerbose.machine_SFI_number || '–'}}</td>
              </tr>
              <tr>
                <td class="footer-tb-head">CBM approved by</td>
                <td class="footer-tb-td">{{reportVerbose.cbm_class_approved_by || '–'}}</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td class="footer-tb-head">Analyzed by</td>
                <td class="footer-tb-td">{{analysedBy || '–'}}</td>
              </tr>
              <tr>
                <td class="footer-tb-head">Approved by</td>
                <td class="footer-tb-td">{{approvedBy || '–'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
