import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ApiListController } from '../../backend-api/api-list-controller';
import { MachineInterface } from '../../backend-api/machine/machine';
import { MachineService } from '../../backend-api/machine/machine.service';
import { SiteGroupFamily } from '../../backend-api/site-group-family/site-group-family';
import { SiteGroupFamilyService } from '../../backend-api/site-group-family/site-group-family.service';
import { SiteGroup } from '../../backend-api/site-group/site-group';
import { SiteGroupService } from '../../backend-api/site-group/site-group.service';
import { SiteInterface } from '../../backend-api/site/site';
import { SiteService } from '../../backend-api/site/site.service';

export interface FilterParameters {
  siteGroupFamily?: number;
  siteGroup?: number;
  site?: number;
  machine?: number;
}

@Component({
  selector: 'app-report-archive',
  templateUrl: './report-archive.component.html',
  styleUrls: ['./report-archive.component.scss'],
})
export class ReportArchiveComponent implements OnInit, OnDestroy {
  // SiteGroupFamilies
  siteGroupFamilies: SiteGroupFamily[];
  private currentSelectedFleet = 0;

  // SiteGroups
  siteGroups: SiteGroup[];

  // Sites
  sites: SiteInterface[];
  selectedSite: SiteInterface;
  sitePaginationSize = 20;
  siteApiPaginator: ApiListController<SiteInterface, any>;
  isSiteFilterExpanded = false;

  selectedTabIndex: number;
  @ViewChild('sitePaginator') sitePaginator: MatPaginator;

  private currentSiteFilter = {};
  private currentSiteSearch = '';

  // Machines
  machines: MachineInterface[];
  selectedMachine: MachineInterface;
  machinePaginationSize = 20;
  machineApiPaginator: ApiListController<MachineInterface, any>;
  isMachineFilterExpanded = false;
  @ViewChild('machinePaginator') machinePaginator: MatPaginator;
  private currentMachineFilter = {};
  private currentMachineSearch = '';

  // Filter form
  reportApiFilterForm: FormGroup;
  currentFilterParameters: FilterParameters = {};

  destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private siteGroupFamilyService: SiteGroupFamilyService,
    private siteGroupService: SiteGroupService,
    private siteService: SiteService,
    private machineService: MachineService
  ) {}

  async ngOnInit(): Promise<void> {
    this.initReportForm();
    this.initPaginators();
    this.applyAPIFilterOnFormChanges();
    this.applySiteApiSearchOnChanges();
    this.applyMachineApiSearchOnChanges();

    this.loadInitialData();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  /**
   * Loads site group families, and loads first page for sites, machines and reports.
   *  Select machine or site if url parameters ('machine' or 'site') are provided.
   */
  private async loadInitialData(): Promise<void> {
    this.retrieveSiteGroupFamilies();

    if (!this.isValidQueryParameters()) {
      this.initWithoutQueryParams();
      return;
    }

    try {
      await this.initWithQueryParams();
    } catch (error) {
      this.initWithoutQueryParams();
    }
  }

  private isValidQueryParameters() {
    if (this.route.snapshot.queryParams['machine']) return true;
    if (this.route.snapshot.queryParams['site']) return true;
    return false;
  }

  /**
   * Selects site and machine based on url parameter 'machine' and 'site.
   */
  private async initWithQueryParams(): Promise<void> {
    let machineId = this.route.snapshot.queryParams['machine'];
    let siteId = this.route.snapshot.queryParams['site'];

    let machine: MachineInterface = null;
    let site: SiteInterface = null;

    // first try to retrieve site and machine. If not existing, throw exception.
    if (siteId) site = await this.siteService.retrieve(siteId);
    if (machineId) machine = await this.machineService.retrieve(machineId);

    // Then update site and machine based on the response.
    if (site) this.selectSite(site, !machine);
    if (machine) this.selectMachine(machine);
  }

  private initWithoutQueryParams() {
    this.machineApiPaginator.changePage(1);
    this.siteApiPaginator.changePage(1);
  }

  private initReportForm() {
    this.reportApiFilterForm = this.fb.group({
      selectedFleet: 0,
      selectedSubFleet: [{ value: 0, disabled: true }],
      siteSearch: '',
      machineSearch: '',
      selectedSite: 0,
      selectedMachine: 0,
    });
  }

  private initPaginators() {
    this.siteApiPaginator = new ApiListController<SiteInterface, any>(
      this.sitePaginationSize,
      this.siteService
    );

    this.machineApiPaginator = new ApiListController<MachineInterface, any>(
      this.machinePaginationSize,
      this.machineService
    );
  }

  private applyAPIFilterOnFormChanges() {
    this.reportApiFilterForm.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(1) // To prevent race conditions when changing values in typescript.
      )
      .subscribe((changes) => {
        if (changes.siteSearch !== this.currentSiteSearch) return; // skip if changes from site search bar.
        if (changes.machineSearch !== this.currentMachineSearch) return; // skip if changes from machine search bar.

        this.fleetFilterOnFormChange(changes);
        this.reportFilterOnFormChanges(changes);
        this.machineFilterOnFormChanges(changes);
        this.siteFilterOnFormChanges(changes);
      });
  }

  private applySiteApiSearchOnChanges() {
    this.reportApiFilterForm.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(500))
      .subscribe((changes) => {
        if (changes.siteSearch === this.currentSiteSearch) return; // skip if changes not from site search bar.

        this.currentSiteSearch = changes.siteSearch;

        let nextFilter = { ...this.currentSiteFilter };
        if (changes.siteSearch.trim())
          nextFilter = { ...nextFilter, search: changes.siteSearch.trim() };

        this.siteApiPaginator.applyApiFilter(nextFilter);
        this.sitePaginator.firstPage();
      });
  }
  private applyMachineApiSearchOnChanges() {
    this.reportApiFilterForm.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(500))
      .subscribe((changes) => {
        if (changes.machineSearch === this.currentMachineSearch) return; // skip if changes not from machine search bar.

        this.currentMachineSearch = changes.machineSearch;

        let nextFilter = { ...this.currentMachineFilter };
        if (changes.machineSearch.trim())
          nextFilter = { ...nextFilter, search: changes.machineSearch.trim() };

        this.machineApiPaginator.applyApiFilter(nextFilter);
        this.machinePaginator.firstPage();
      });
  }

  private fleetFilterOnFormChange(changes) {
    if (changes.selectedFleet !== this.currentSelectedFleet) {
      this.currentSelectedFleet = changes.selectedFleet;
      let subFleetController = this.reportApiFilterForm.get('selectedSubFleet');
      subFleetController.setValue(0, { onlySelf: true, emitEvent: false });
      subFleetController.disable({ onlySelf: true, emitEvent: false });
      this.deselectSite();

      changes.selectedSubFleet = 0;
      this.siteGroups = [];

      if (changes.selectedFleet) {
        this.siteGroupService
          .list({
            site_group_family: changes.selectedFleet,
            page_size: 1000,
          })
          .then((response) => {
            this.siteGroups = response.results;
            if (response.results.length > 0)
              subFleetController.enable({ onlySelf: true, emitEvent: false });
          });
      }
    }
  }

  private reportFilterOnFormChanges(changes) {
    let reportFilter: FilterParameters = {};
    if (changes.selectedFleet)
      reportFilter = {
        siteGroupFamily: changes.selectedFleet,
        ...reportFilter,
      };
    if (changes.selectedSubFleet)
      reportFilter = {
        siteGroup: changes.selectedSubFleet,
        ...reportFilter,
      };
    if (changes.selectedSite)
      reportFilter = {
        site: changes.selectedSite,
        ...reportFilter,
      };
    if (changes.selectedMachine)
      reportFilter = {
        machine: changes.selectedMachine,
        ...reportFilter,
      };

    this.currentFilterParameters = reportFilter;
  }

  private siteFilterOnFormChanges(changes) {
    this.currentSiteFilter = {};
    if (changes.selectedFleet)
      this.currentSiteFilter = {
        site_group__site_group_family: changes.selectedFleet,
        ...this.currentSiteFilter,
      };
    if (changes.selectedSubFleet)
      this.currentSiteFilter = {
        site_group: changes.selectedSubFleet,
        ...this.currentSiteFilter,
      };

    let nextSiteFilter = { ...this.currentSiteFilter };
    if (this.currentSiteSearch.trim()) {
      nextSiteFilter = {
        ...nextSiteFilter,
        search: this.currentSiteSearch.trim(),
      };
    }

    this.siteApiPaginator.applyApiFilter({
      ...nextSiteFilter,
    });
    this.sitePaginator.firstPage();
  }

  private machineFilterOnFormChanges(changes) {
    this.currentMachineFilter = {};
    if (changes.selectedFleet)
      this.currentMachineFilter = {
        site__site_group__site_group_family: changes.selectedFleet,
        ...this.currentMachineFilter,
      };
    if (changes.selectedSubFleet)
      this.currentMachineFilter = {
        site__site_group: changes.selectedSubFleet,
        ...this.currentMachineFilter,
      };
    if (changes.selectedSite)
      this.currentMachineFilter = {
        site: changes.selectedSite,
        ...this.currentMachineFilter,
      };

    let nextMachineFilter = { ...this.currentMachineFilter };
    if (this.currentMachineSearch.trim()) {
      nextMachineFilter = {
        ...nextMachineFilter,
        search: this.currentMachineSearch.trim(),
      };
    }

    this.machineApiPaginator.applyApiFilter({
      ...nextMachineFilter,
    });
    this.machinePaginator.firstPage();
  }

  private retrieveSiteGroupFamilies() {
    this.siteGroupFamilyService
      .list({ page_size: 1000 })
      .then((siteGroupFamilies) => {
        this.siteGroupFamilies = siteGroupFamilies.results;
      });
  }

  onTabChanged(event: MatTabChangeEvent) {
    this.selectedTabIndex = event.index;
    // If tab is odx file tab collapse machine filter
    if (event.index === 2) this.isMachineFilterExpanded = false;
  }

  /**
   * Toggles site in site filter.
   * @param site
   */
  toggleSite(site: SiteInterface) {
    this.selectedSite?.id === site.id
      ? this.deselectSite()
      : this.selectSite(site);
  }

  /**
   * Toggle machine in machine filter
   * @param machine
   */
  toggleMachine(machine: MachineInterface) {
    this.selectedMachine?.id === machine.id
      ? this.deselectMachine()
      : this.selectMachine(machine);
  }

  deselectMachine(emitFilterEvent = true) {
    this.selectedMachine = null;
    this.reportApiFilterForm
      .get('selectedMachine')
      .setValue(0, { emitEvent: emitFilterEvent });
  }

  selectMachine(machine: MachineInterface, emitFilterEvent = true) {
    // selecting nothing is the same as deselecting
    if (!machine) {
      this.deselectMachine(emitFilterEvent);
      return;
    }
    this.selectedMachine = machine;
    this.reportApiFilterForm.get('selectedMachine').setValue(machine.id);
  }

  deselectSite() {
    this.selectedSite = null;
    this.machines = null;
    this.reportApiFilterForm.get('selectedSite').setValue(0);
    this.deselectMachine();
  }

  selectSite(site: SiteInterface, emitFilterEvent = true) {
    // selecting nothing is the same as deselecting
    if (!site) {
      this.deselectSite();
      return;
    }
    this.selectedSite = site;
    this.machineService
      .list({ site: site.id, page_size: 1000, ordering: 'name' })
      .then((response) => {
        this.machines = response.results;
      });
    this.reportApiFilterForm
      .get('selectedSite')
      .setValue(site.id, { emitEvent: false });
    this.deselectMachine(emitFilterEvent);
  }

  /**
   * Expand/collapse site filter
   */
  toggleSiteFilter() {
    this.isSiteFilterExpanded = !this.isSiteFilterExpanded;
  }

  /**
   * Expand/collapse machine filter
   */
  toggleMachineFilter() {
    this.isMachineFilterExpanded = !this.isMachineFilterExpanded;
  }
}
