import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

export interface Instrument {
  serialNumber: string;
  site: string;

  hardware: string;
  hardwareIcon: string;
  lastInspectionDate: Date;
  nextInspectionDate: Date;
}

const DEVICES: Instrument[] = [
  {
    serialNumber: "09519327",
    site: "Skandi Acergy",

    hardware: 'VibExpert II',
    hardwareIcon: 'vibexert2',

    lastInspectionDate: new Date("2021-08-18"),
    nextInspectionDate: new Date("2022-08-18"),
  },
  {
    serialNumber: "09519231",
    site: "Skandi Acergy",

    hardware: 'VibScanner II',
    hardwareIcon: 'vibscanner2',

    lastInspectionDate: new Date("2020-07-02"),
    nextInspectionDate: new Date("2021-07-02"),
  },
  {
    serialNumber: "09519328",
    site: "Skandi Acergy",

    hardware: 'VibScanner I',
    hardwareIcon: 'vibscanner1',

    lastInspectionDate: new Date("2021-08-18"),
    nextInspectionDate: new Date("2022-08-18"),
  },
  {
    serialNumber: "09519117",
    site: "Skandi Iceman",

    hardware: 'VibExpert II',
    hardwareIcon: 'vibexert2',

    lastInspectionDate: new Date("2021-08-18"),
    nextInspectionDate: new Date("2022-08-18"),
  },
  {
    serialNumber: "09519327",
    site: "Skandi Iceman",

    hardware: 'VibExpert II',
    hardwareIcon: 'vibexert2',

    lastInspectionDate: new Date("2021-08-18"),
    nextInspectionDate: new Date("2022-08-18"),
  },
  {
    serialNumber: "09519327",
    site: "Skandi Iceman",

    hardware: 'VibExpert II',
    hardwareIcon: 'vibexert2',

    lastInspectionDate: new Date("2021-08-18"),
    nextInspectionDate: new Date("2022-08-18"),
  },
  {
    serialNumber: "09519327",
    site: "Skandi Iceman",

    hardware: 'VibExpert II',
    hardwareIcon: 'vibexert2',

    lastInspectionDate: new Date("2021-08-18"),
    nextInspectionDate: new Date("2022-08-18"),
  },
  {
    serialNumber: "09519327",
    site: "Skandi Iceman",

    hardware: 'VibExpert II',
    hardwareIcon: 'vibexert2',

    lastInspectionDate: new Date("2021-08-18"),
    nextInspectionDate: new Date("2022-08-18"),
  },
  {
    serialNumber: "09519327",
    site: "Skandi Iceman",

    hardware: 'VibExpert II',
    hardwareIcon: 'vibexert2',

    lastInspectionDate: new Date("2021-08-18"),
    nextInspectionDate: new Date("2022-08-18"),
  },
  {
    serialNumber: "09519327",
    site: "Skandi Iceman",

    hardware: 'VibExpert II',
    hardwareIcon: 'vibexert2',

    lastInspectionDate: new Date("2021-08-18"),
    nextInspectionDate: new Date("2022-08-18"),
  },
  {
    serialNumber: "09519327",
    site: "Skandi Iceman",

    hardware: 'VibExpert II',
    hardwareIcon: 'vibexert2',

    lastInspectionDate: new Date("2021-08-18"),
    nextInspectionDate: new Date("2022-08-18"),
  },
  {
    serialNumber: "09519327",
    site: "Skandi Iceman",

    hardware: 'VibExpert II',
    hardwareIcon: 'vibexert2',

    lastInspectionDate: new Date("2021-08-18"),
    nextInspectionDate: new Date("2022-08-18"),
  },
];

@Component({
  selector: 'app-instruments',
  templateUrl: './instruments.component.html',
  styleUrls: ['./instruments.component.scss'],
})
export class InstrumentsComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource(DEVICES);

  displayedColumns: string[] = [
    'hardwareIcon',
    'site',
    'serialNumber',
    'hardware',
    'lastInspectionDate',
    'nextInspectionDate',
  ];

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    // TODO: Sort by nextInspectionDate
    this.dataSource.sort = this.sort;
  }

  public isDateOverdue(date: Date): Boolean {
    return date < new Date();
  }
}
