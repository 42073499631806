import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

export interface MaintenancePlannerInterface {
  tag: string;
  vessel: string;
  machine: string;
  description: string;
  date: string;
  send: boolean;
  confirmation: boolean;
  edit: boolean;
}

const MAINTENANCE_PLANNER_DATA: MaintenancePlannerInterface[] = [
  {
    tag: '647.035.01',
    vessel: 'Deep Aurore',
    machine: 'Bow tunnel thruster no.2',
    description:
      'Check dampers for wear. Perform new measurements within one month to follow possible development.',
    date: '15 Feb 2021',
    send: true,
    confirmation: true,
    edit: false,
  },
  {
    tag: '667.001.01',
    vessel: 'Deep Recovery',
    machine: 'Bow tunnel thruster no.1',
    description:
      'Replace bearing 86002. Use thruster only when necessary. Monitor temperature and noise if thruster is used.',
    date: '22 Feb 2021',
    send: true,
    confirmation: true,
    edit: false,
  },
  {
    tag: '667.002.06',
    vessel: 'Deep Recovery',
    machine: 'Bow tunnel thruster no.1',
    description:
      'Replace bearing 86002. Use thruster only when necessary. Monitor temperature and noise if thruster is used.',
    date: '22 Feb 2021',
    send: false,
    confirmation: false,
    edit: true,
  },
  {
    tag: '865.011.16',
    vessel: 'Deep Aurore',
    machine: 'Power Transmission output generator no.1 PS',
    description:
      'Lubricate the bearings, and perform monthly measurements to follow a possible development. Pay attention to any audible noise and/or increase in temperature.',
    date: '22 Feb 2021',
    send: false,
    confirmation: false,
    edit: true,
  },
  {
    tag: '404.03',
    vessel: 'Skandi Poseidon',
    machine: 'Bow retractable thruster no.3',
    description:
      'We still recommend a further inspection of the propeller shaft, if not already done.Perform an oil analysis of the gear. Follow normal vibration measurement interval. Remember to perform the measurements at the exact same operating conditions, speed and load, each time measurements are taken.',
    date: '22 Feb 2021',
    send: false,
    confirmation: false,
    edit: true,
  },
];

@Component({
  selector: 'app-maintenance-planner',
  templateUrl: './maintenance-planner.component.html',
  styleUrls: ['./maintenance-planner.component.scss'],
})
export class MaintenancePlannerComponent implements OnInit {
  displayedColumns: string[] = [
    'tag',
    'vessel',
    'machine',
    'description',
    'date',
    'send',
    'edit',
    'confirmation',
  ];
  dataSource = new MatTableDataSource(MAINTENANCE_PLANNER_DATA);

  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  constructor() {}

  confirmJob(index: number) {
    MAINTENANCE_PLANNER_DATA[index].send = true;
    MAINTENANCE_PLANNER_DATA[index].edit = false;
    setTimeout(() => {
      MAINTENANCE_PLANNER_DATA[index].confirmation = true;
    }, 2000);
  }

  ngOnInit(): void {}
}
