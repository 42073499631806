import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { VibrationReportDialogComponent } from '../dialogs/vibration-report-dialog/vibration-report-dialog.component';
import { Subject, Subscription } from 'rxjs';
import {
  VibrationReport,
  VibrationReportVerboseInterface,
} from '../backend-api/vibration-report/vibration-report';
import { BackendPaginatedTableDataSource } from '../backend-api/api-paginated-table-data-source';
import { VibrationReportService } from '../backend-api/vibration-report/vibration-report.service';
import { getVibrationReportStatus } from '../ts-tools/utils';
import { VibrationReportVerboseService } from '../backend-api/vibration-report/vibration-report-verbose.service';
import { FormControl } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';

interface VibrationReportTableData {
  dateApproved: string;
  machineName: string;
  status: number;
  vibReportId: number;
}
@Component({
  selector: 'app-vibration-report-table',
  templateUrl: './vibration-report-table.component.html',
  styleUrls: ['./vibration-report-table.component.scss'],
})
export class VibrationReportTableComponent implements OnInit {
  @Input() siteId: number;
  @Input() machineId: number;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  searchForm = new FormControl();

  destroy$ = new Subject<void>();

  public displayedColumns: string[] = ['dateApproved', 'machineName', 'status'];

  assetFilter = {};

  isLoading = false;
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private vibrationReportVerboseService: VibrationReportVerboseService
  ) {}

  vibrationReportTableDataSource = new BackendPaginatedTableDataSource<
    VibrationReportVerboseInterface,
    VibrationReportTableData
  >(
    this.vibrationReportVerboseService,
    (vibReports) => {
      let tableData: VibrationReportTableData[] = [];
      vibReports.forEach((vibReport) => {
        tableData.push({
          machineName: vibReport.machine_name,
          status: getVibrationReportStatus(vibReport.severity_level)['number'],
          dateApproved: vibReport.date_approved,
          vibReportId: vibReport.id,
        });
      });
      return tableData;
    },
    10
  );

  ngOnInit() {
    if (this.siteId) this.assetFilter = { site: this.siteId };
    if (this.machineId)
      this.assetFilter = {
        ...this.assetFilter,
        ...{ machine: this.machineId },
      };

    this.isLoading = true;
    this.vibrationReportTableDataSource.filterBy({...this.assetFilter, ordering:"-date_approved"}).then(() => {
      this.isLoading = false;
    });
    this.searchForm.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(300))
      .subscribe((VibReportSearch) => {
        this.applyVibReportSearchFilter(VibReportSearch);
      });
  }
  ngAfterViewInit() {
    this.vibrationReportTableDataSource.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  async updatePage(event: any) {
    this.isLoading = true;
    await this.vibrationReportTableDataSource.updatePage(event);
    this.isLoading = false;
  }

  async applyVibReportSearchFilter(search: string) {
    this.isLoading = true;
    await this.vibrationReportTableDataSource.filterBy({
      ...this.assetFilter,
      ...{ search: search },
      ordering:"-date_approved"
    });
    this.isLoading = false;
  }

  redirect(siteId: string) {
    this.router.navigate(['/site', { id: siteId }]);
  }

  openDialog(vibrationReportId: number): void {
    const dialogRef = this.dialog.open(VibrationReportDialogComponent, {
      width: '1050px',
      data: { vibrationReportId: vibrationReportId },
      panelClass: ['dialog__no-padding'],
    });
  }
}
