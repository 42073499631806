import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

export interface SparePartsInterface {
  site: string;
  description: string;
  type: string;
  picture?: string;
  provider: string;
  price: string;
  ordered: boolean;
}

const SPARE_PARTS_INTERFACE: SparePartsInterface[] = [
  {
    site: 'Deep\xa0Aurore\xa0\xa0\xa0\xa0',
    description: 'Bearing',
    type: '6036',
    provider: 'SKF',
    picture: '1',
    price: "€1\xa0019.10\xa0\xa0\xa0\xa0",
    ordered: false,
  },
  {
    site: 'Deep\xa0Recovery',
    description: 'Bearing',
    type: '86002',
    provider: 'SKF',
    picture: '2',
    price: "€1\xa0500.72",
    ordered: false,
  },
];

@Component({
  selector: 'app-spare-parts',
  templateUrl: './spare-parts.component.html',
  styleUrls: ['./spare-parts.component.scss'],
})
export class SparePartsComponent implements OnInit {
  displayedColumns: string[] = [
    'picture',
    'site',
    'description',
    'price',
    'order',
    'ordered',
  ];

  dataSource = new MatTableDataSource(SPARE_PARTS_INTERFACE);

  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  constructor() {}

  orderPart(index: number) {
    setTimeout(() => {
      SPARE_PARTS_INTERFACE[index].ordered = true;
    }, 2000);
  }

  ngOnInit(): void {}
}
