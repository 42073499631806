import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LRUDMixin, PaginatedListResponse } from '../api-mixins';
import { environment } from 'src/environments/environment';
import { SourceFileInterface } from './source-file';
import { AuthService } from '../../_services/auth.service';
import { BlobStorageService } from '../../_services/azure-storage/azure-storage.service';
import { FileUploader } from '../file-uploader';

@Injectable({
  providedIn: 'root',
})
export class SourceFileService extends LRUDMixin<SourceFileInterface> {
  protected modelBaseUrl: string;
  constructor(protected http: HttpClient, private authService: AuthService) {
    super(environment.BACKEND_BASE_URL + 'source-file/', http);
  }

  public list(
    filter?: { [id: string]: string | number | boolean | string[] | number[] },
    search?: string,
    ordering?: string,
    pageSize?: number,
    customUrl?: string
  ): Promise<PaginatedListResponse<SourceFileInterface>> {
    // Return only files that are not empty
    let _filter = { ...filter, is_file_empty: false };
    return super.list(_filter, search, ordering, pageSize, customUrl);
  }

  async create(
    file: File,
    createData?: Partial<SourceFileInterface>
  ): Promise<SourceFileInterface> {
    /**
     * Creates and instance in the database with write permission url to allocated blob on datalake.
     */
    let _createData: Partial<SourceFileInterface> = {
      name: file.name,
      size: file.size,
      user: this.authService.loggedInUser.user_id,
      company: this.authService.loggedInUser.company_id,
      is_file_empty: true,
    };

    if (createData) _createData = { ..._createData, ...createData };

    return this.http
      .post<SourceFileInterface>(this.modelBaseUrl, _createData)
      .toPromise();
  }
}

@Injectable({
  providedIn: 'root',
})
export class SourceFileUploadService extends FileUploader<SourceFileInterface> {
  constructor(
    protected sourceFileService: SourceFileService,
    protected blobStorageService: BlobStorageService
  ) {
    super(sourceFileService, blobStorageService);
  }
}
