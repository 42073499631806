<div style="padding: 3rem;">
  <div class="row">
    <div class="col-12 mb-4 site-header" style="height: 100px;">
      <div class="title">
        <h2>Maintenance planner</h2>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <button mat-raised-button color="primary">IMPORT MAINTENANCE JOBS</button>
      &nbsp;
      <button mat-raised-button color="primary">NEW JOB</button>
    </div>
  </div>

  <div class="row" style="height: 1rem;"></div>

  <div class="row">
    <table mat-table [dataSource]="dataSource" matSort style="width: 100%;">

      <ng-container matColumnDef="tag">
        <th width="150px" mat-header-cell *matHeaderCellDef mat-sort-header> Tag </th>
        <td mat-cell *matCellDef="let maintenanceJob"> {{maintenanceJob.tag}} </td>
      </ng-container>

      <ng-container matColumnDef="vessel">
        <th width="150px" mat-header-cell *matHeaderCellDef mat-sort-header> Vessel </th>
        <td mat-cell *matCellDef="let maintenanceJob"> {{maintenanceJob.vessel}} </td>
      </ng-container>

      <ng-container matColumnDef="machine">
        <th width="200px" mat-header-cell *matHeaderCellDef mat-sort-header> Machine </th>
        <td mat-cell *matCellDef="let maintenanceJob"> {{maintenanceJob.machine}} </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
        <td mat-cell *matCellDef="let maintenanceJob"> {{maintenanceJob.description}} </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th width="150px" mat-header-cell *matHeaderCellDef mat-sort-header> Date planned </th>
        <td mat-cell *matCellDef="let maintenanceJob"> {{maintenanceJob.date}} </td>
      </ng-container>

      <ng-container matColumnDef="send">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Issue recomendation </th>
        <td mat-cell *matCellDef="let maintenanceJob; let i = index" style="text-align: center;">
          <button mat-raised-button color="primary" [disabled]="maintenanceJob.send"
            (click)="confirmJob(i)">{{maintenanceJob.send ? "SENT" : "SEND"}}</button>
        </td>
      </ng-container>

      <ng-container matColumnDef="confirmation">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Recomendation received </th>
        <td mat-cell *matCellDef="let maintenanceJob" style="text-align: center;"> <span
            *ngIf="maintenanceJob.confirmation">
            <mat-icon>check_circle_outline</mat-icon>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Edit </th>
        <td mat-cell *matCellDef="let maintenanceJob; let i = index" style="text-align: center;">
          <button mat-icon-button disabled [disabled]="maintenanceJob.send">
            <mat-icon>edit</mat-icon>
          </button>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
