import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { Ticket } from '../backend-api/ticket/ticket';
import { TicketService } from '../backend-api/ticket/ticket.service';
import { AuthService } from '../_services/auth.service';

declare var ol: any; // For the map.  OpenLayer


@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent implements OnInit {
  public Object = Object; // Required to loop object keys in html
  company_logo_url: string;
  newSupportTicketForm: FormGroup;
  karstenMoholtCoordinates = [5.1905, 60.4400431];
  map: any;
  subscriptions: Subscription;

  public get attachments() {
    return this.newSupportTicketForm.get('attachments').value;
  }

  public set attachments(value) {
    this.newSupportTicketForm.get('attachments').setValue(value);
  }

  constructor(
    private fb: FormBuilder,
    private ticketService: TicketService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.company_logo_url = this.authService.loggedInUser.company_logo_light_bg;
    this.newSupportTicketForm = this.fb.group({
      subject: ['', [Validators.required]],
      description: ['', [Validators.required]],
      attachments: [{}],
    });
    this.initializeMap();
  }

  /**
   * Initializes the map
   */
  initializeMap() {
    let map = (this.map = new ol.Map({
      // Weird workaround at point of development

      target: 'map',
      layers: [
        new ol.layer.Tile({
          source: new ol.source.OSM(),
        }),
      ],
      view: new ol.View({
        center: ol.proj.fromLonLat(this.karstenMoholtCoordinates),
        zoom: 10,
      }),
    }));
    var layer = new ol.layer.Vector({
      source: new ol.source.Vector({
        features: [
          new ol.Feature({
            geometry: new ol.geom.Point(
              ol.proj.fromLonLat(this.karstenMoholtCoordinates)
            ),
          }),
        ],
      }),
    });
    map.addLayer(layer);
  }
  /**
   * Submits new ticket
   * @param formDirective
   * @returns
   */
  submitNewTicket(formDirective: FormGroupDirective) {
    if (!this.newSupportTicketForm.valid) return;
    let ticket: Ticket = {
      subject: this.newSupportTicketForm.get('subject').value,
      description: this.newSupportTicketForm.get('description').value,
      via: {
        channel: 'web',
        source: {
          from: {
            title: 'skAIwatch portal',
          },
          to: {
            title: 'skAIwatch backend',
          },
          rel: 'api',
        },
      },
    };
    // Create custom form data to submit
    let formData = new FormData();
    // Add files to form data
    for (const fileName of Object.keys(this.attachments)) {
      const element = this.attachments[fileName];
      formData.append('attachments', element, element.name);
    }
    // Add ticket to form data
    formData.append('ticket', JSON.stringify(ticket));

    // Send ticket and handle response.
    this.ticketService.createWithAttachments(formData).then(
      (response) => {
        alert('Ticket submitted! Check your email for replies and updates.');
        window.location.reload();
      },
      (err) => {
        alert(err.error);
        console.log('error', err);
      }
    );
  }

  /**
   * Uploads file
   * @param files
   */
  uploadFile(files) {
    let newFiles = files;
    for (const file of newFiles) {
      this.attachments[file.name] = file;
    }
  }

  /**
   * Deletes attachment
   * @param fileName
   */
  deleteAttachment(fileName) {
    delete this.attachments[fileName];
  }
}
