<div class="bg-image">
  <div class="bg-text intro-animation">

    <div class="head-container">
      <img src="assets/img/logo/skaiwatch_white.svg" class="login-logo">
    </div>

    <div class="login-group" *ngIf="!loading; else loadSpinner">
      <div *ngIf="selectedLogin===null" class="select-login-container">
        <div class="login-type-description">
          Please select login method
        </div>
        <button mat-raised-button (click)="selectLogin(1)" class="login-type">
          <div style="display: flex; justify-content: center; align-items: center;">
            <img src="assets/img/logo/km_wheel_white.svg">
            skAIwatch
          </div>
        </button>
        <div class="break"></div>
        <button mat-raised-button (click)="startMicrosoftPopUpLogin()" class="login-type">
          <div style="display: flex; justify-content: center; align-items: center;">
            <img src="assets/img/logo/Microsoft_logo.svg">
            Microsoft
          </div>
        </button>
        <div class="login-failed">
          <span *ngIf="error">{{ error.other }}<br /></span>
        </div>
      </div>
      <div *ngIf="selectedLogin===1">
        <form class="login-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <button type="button" *ngIf="selectedLogin!==null" mat-button (click)="selectLogin(null)" style="color:white">
            <div style="display: flex; align-items: center; justify-content: center;">
              <img style="height: 12px;" src="assets/img/misc/arrow-left.svg">
              <span style="font-weight: normal; margin-left: 5px;">Return</span>
            </div>
          </button>

          <mat-form-field class="login-field">
            <input matInput placeholder="Email" formControlName="email" [ngClass]="{ 'is-invalid': f.email.errors }"
              required>
          </mat-form-field>
          <div class="login-failed" *ngIf="error.email">
            <span>{{error.email}}</span>
          </div>


          <mat-form-field class="login-field">
            <input matInput placeholder="Password" formControlName="password" type="password"
              [ngClass]="{ 'is-invalid': f.password.errors }" required>
          </mat-form-field>
          <div class="login-failed" *ngIf="error.password">
            <span>{{error.password}}</span>
          </div>

          <button mat-raised-button type="submit" class="login-type">Log in</button>

          <a style="font-weight: normal;" href="{{resetPasswordUrl}}">Reset password</a>
          <div class="login-failed col-sm-12">
            <span *ngIf="error">{{ error.other }}<br /></span>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!------------------->
  <!-- Start: footer -->
  <!------------------->
  <footer class="page-footer" id="footer">
    <div class="footer-copyright text-center py-3">© 2020 Copyright
      <a href="https://karsten-moholt.no/" target="_blank"> Karsten Moholt AS</a>
    </div>
  </footer>
  <!------------------->
  <!-- end: footer   -->
  <!------------------->
  <ng-template #loadSpinner>
    <mat-spinner></mat-spinner>
  </ng-template>

</div>
