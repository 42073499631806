import { getVibrationReportStatus } from '../../ts-tools/utils';
import { CompanyInterface } from '../company/company';
import { MachineInterface } from '../machine/machine';
import { SiteInterface } from '../site/site';

export interface SiteReportStatus {
  machine_id: number;
  machine_name: string;
  machine_group: string;
  latest_report: VibrationReportInterface;
}
export interface VibrationReportInterface {
  amps_a: number | null;
  analysed_by: number | null;
  analysis: string | null;
  approved: boolean;
  approved_by: number | null;
  date_approved: string | null;
  cbm_approved_by: string | null;
  company: CompanyInterface | number;
  site: SiteInterface | number;
  machine: MachineInterface | number;
  measurement: number | null;
  imo_number: string | null;
  load_kw: number | null;
  pitch_pct: number | null;
  recommendation: string | null;
  date_created: string;
  running_hours: number | null;
  no_new_measurement: boolean;
  is_maintenance_period: boolean;
  so_number: string | null;
  severity_level: string;
  relevant_defects: string[];
  sfi_tag: string | null;
  speed_rpm: number | null;
  graphs: string | null;
  id: number;
  confidence_level: string;

  machine_statuses?: MachineStatusVerbose[];
  bearing_statuses?: BearingStatusVerbose[];
  oil_statuses?: OilStatusVerbose[];
  electrical_statuses?: ElectricalStatusVerbose[];

  report_images?: ReportImage[];
  analytics_instances?: AnalyticsInstance[];
}

export interface VibrationReportFilterParameters {
  site__company: string;
  site__company__name: string;
  site: number;
  site__in: number[];
  site__name: string;
  site__site_group: string;
  site__site_group__site_group_family: string;
  machine: string;
  machine__name: string;
  severity_level: string;
  severity_level__in: string[];
  published: string;
  approved_by: string;
  analysed_by: string;
  date_created: string;
  date_created__range: string[];
  date_created__isnull: boolean;
  date_approved: string;
  date_approved__range: string[];
  date_approved__isnull: boolean;
}

export interface MachineStatus {
  comment: string | null;
  defect: string[] | null;
  status: string | null;
}

export interface BearingStatuses {
  comment: string | null;
  defect: string[] | null;
  status: string | null;
  bearing: string | null;
  location: string | null;
}

export interface OilStatus {
  comment: string | null;
  defect: string[] | null;
  status: string | null;
}

export interface Bearing {
  manufacturer: string;
  type: string;
  BPFI?: number | null;
  BPFO?: number | null;
  BSFx2?: number | null;
  FTF?: number | null;
  id?: number;
}

export interface BearingDefect {
  name: string;
  is_proactive?: boolean;
  id?: number;
}

export interface Analyst {
  user: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
  };
  certification_level?: {
    analyst: number;
    level: number;
    datestamp: string;
    assignee: number;
    id: number;
  };
  id?: number;
}

export interface MachineStatusVerbose {
  report: number;
  comment: string;
  defect: {
    name: string;
    is_proactive?: boolean;
    id?: number;
  };
  status: string;
  id: number;
}

export interface BearingStatusVerbose {
  report: number;
  machine_component_bearing?: {
    machine_component: number;
    bearing: Bearing;
    speed_factor: number | null;
    location: string;
    coordinates_3d: number[] | null;
    id: number;
  };

  machine_bearing?: {
    machine: number;
    bearing: Bearing;
    speed_factor: number | null;
    location: string;
    coordinates_3d: number[];
    id: number;
  };

  bearing: Bearing;
  comment: string;
  defect: BearingDefect;
  bearing_severity_level: string | null;
  status: string;
  id: number;
}

export interface OilStatusVerbose {
  report: number | null;
  comment: string | null;
  defect: {
    name: string;
    is_proactive?: boolean;
    id?: number;
  };
  status: string;
  oil_report?: number;
  id: number;
}

export interface ElectricalStatusVerbose {
  report: number | null;
  comment: string | null;
  defect: {
    name: string;
    is_proactive?: boolean;
    id?: number;
  }[];
  status: string;
  id: number;
}

export interface ReportImage {
  report: number;
  index: number;
  id?: number;
  image?: string;
  image_as_svg?: string;
}

export interface AnalyticsInstance {
  id?: number;
  report: number;
  report_image?: number;
  name: string;
  user?: number | null;
  date_created?: string;
  last_modified?: string;
  company: number | null;
  site: number | null;
  machine: number | null;
  machine_component: number | null;
  measurement_position: number | null;
  measurement_context?: number | null;
  selected_orders: number[];
  selected_order_harmonics: number[];
  selected_measurements: number[];
}

export type SeverityLevel =
  | 'Good'
  | 'Warning'
  | 'Alarm'
  | 'Incomplete Data'
  | 'Acceptance Report'
  | '';
export interface VibrationReportVerboseInterface {
  id: number;
  date_created: string;
  date_approved: string;
  analysed_by: Analyst | null;
  analysis: string | null;
  approved_by: Analyst | null;
  published: boolean;
  date_published: string | null;
  company: number;
  company_name: string;
  company_logo: string;
  site: number;
  site_name: string;
  site_location_identifier: string;
  site_cbm_class_approved_by: string;
  machine: number;
  machine_name: string;
  machine_group: string;
  machine_SFI_number: string;
  machine_description: string;
  machine_category: string;
  amps_a: number | null;
  load_kw: number | null;
  pitch_pct: number | null;
  recommendation: string | null;
  running_hours: number | null;
  so_number: string | null;
  severity_level: SeverityLevel;
  speed_rpm: number | null;
  vibration_status_code: string | null;
  intro: string | null;
  consequences: string | null;
  comments: string | null;
  is_proactive: boolean;
  no_new_measurement: boolean;
  is_maintenance_period: boolean;
  report_location: string | null;
  relevant_work_order: string | null;
  sent_to_maintenance_system: boolean;
  confidence_level: string;

  machine_statuses: MachineStatusVerbose[];
  bearing_statuses: BearingStatusVerbose[];
  oil_statuses: OilStatusVerbose[];
  electrical_statuses: ElectricalStatusVerbose[];

  report_images: ReportImage[];
  analytics_instances: AnalyticsInstance[];
}

export class VibrationReport implements VibrationReportVerboseInterface {
  id: number;
  date_created: string;
  date_approved: string;
  analysed_by: Analyst | null;
  analysis: string | null;
  approved_by: Analyst | null;
  published: boolean;
  date_published: string | null;
  company: number;
  company_name: string;
  company_logo: string;
  site: number;
  site_name: string;
  site_location_identifier: string;
  site_cbm_class_approved_by: string;
  machine: number;
  machine_name: string;
  machine_group: string;
  machine_SFI_number: string;
  machine_description: string;
  machine_category: string;
  amps_a: number | null;
  load_kw: number | null;
  pitch_pct: number | null;
  recommendation: string | null;
  running_hours: number | null;
  so_number: string | null;
  severity_level: SeverityLevel;
  speed_rpm: number | null;
  vibration_status_code: string | null;
  intro: string | null;
  consequences: string | null;
  comments: string | null;
  is_proactive: boolean;
  no_new_measurement: boolean;
  is_maintenance_period: boolean;
  report_location: string | null;
  relevant_work_order: string | null;
  sent_to_maintenance_system: boolean;
  confidence_level: string;
  cbm_class_approved_by: string;

  machine_statuses: MachineStatusVerbose[];
  bearing_statuses: BearingStatusVerbose[];
  oil_statuses: OilStatusVerbose[];
  electrical_statuses: ElectricalStatusVerbose[];

  report_images: ReportImage[];
  analytics_instances: AnalyticsInstance[];

  // Assigned in machine dashboard
  status: number;

  // Make compatible with Report class/interface
  get machineName(): string {
    return this.machine_name;
  }

  set machineName(value: string) {
    this.machine_name = value;
  }

  constructor(report: VibrationReportVerboseInterface | any) {
    this.id = report.id;
    this.date_created = report.date_created;
    this.date_approved = report.date_approved;
    this.analysed_by = report.analysed_by;
    this.analysis = report.analysis;
    this.approved_by = report.approved_by;
    this.published = report.published;
    this.date_published = report.date_published;
    this.company = report.company;
    this.company_name = report.company_name;
    this.company_logo = report.company_logo;
    this.site = report.site;
    this.site_name = report.site_name;
    this.site_location_identifier = report.site_location_identifier;
    this.site_cbm_class_approved_by = report.site_cbm_class_approved_by;
    this.machine = report.machine;
    this.machine_name = report.machine_name;
    this.machine_group = report.machine_group;
    this.machine_SFI_number = report.machine_SFI_number;
    this.machine_description = report.machine_description;
    this.machine_category = report.machine_category;
    this.amps_a = report.amps_a;
    this.load_kw = report.load_kw;
    this.pitch_pct = report.pitch_pct;
    this.recommendation = report.recommendation;
    this.running_hours = report.running_hours;
    this.so_number = report.so_number;
    this.severity_level = report.severity_level;
    this.speed_rpm = report.speed_rpm;
    this.vibration_status_code = report.vibration_status_code;
    this.intro = report.intro;
    this.consequences = report.consequences;
    this.comments = report.comments;
    this.is_proactive = report.is_proactive;
    this.no_new_measurement = report.no_new_measurement;
    this.is_maintenance_period = report.is_maintenance_period;
    this.report_location = report.report_location;
    this.relevant_work_order = report.relevant_work_order;
    this.sent_to_maintenance_system = report.sent_to_maintenance_system;
    this.confidence_level = report.confidence_level;
    this.cbm_class_approved_by = report.cbm_class_approved_by;

    this.machine_statuses = report.machine_statuses;
    this.bearing_statuses = report.bearing_statuses;
    this.oil_statuses = report.oil_statuses;
    this.electrical_statuses = report.electrical_statuses;

    this.report_images = report.report_images;
    this.analytics_instances = report.analytics_instances;
    this.status = getVibrationReportStatus(this.severity_level).number;
  }
}

export interface PdfResponse {
  url: string;
}
