<div class="overview-container selectable-text light-theme">

  <!---------------------------------------------------------------------->
  <!--                    Title                     									  -->
  <!---------------------------------------------------------------------->
  <div class="overview-title-container" *ngIf="input">

    <div style="margin-right: auto;">
      <div class="machine-name">{{input?.machineName}}
      </div>
      <div class="sfi">SFI/TAG&nbsp;&nbsp;
        {{input?.machineSfi}}</div>
    </div>

    <div class="icon-buttons">
      <i *ngIf="!isLoadingPdf else loadingSpinner" class="fas fa-download button"
        [ngClass]="{'disabled': !vibrationReport}" matTooltip="Download PDF" matTooltipPosition="below"
        [matTooltipDisabled]="!vibrationReport" (click)="saveVibrationReportPdf()"
       ></i>
      <i [routerLink]="['/archive']" [queryParams]="{machine: input.machineId, site:input.siteId}"
        class="fas fa-archive button" matTooltip="Show report history"></i>
    </div>
  </div>


  <div class="report-container" *ngIf="vibrationReport">
    <!---------------------------------------------------------------------->
    <!--                    Title                     									  -->
    <!---------------------------------------------------------------------->
    <div class="highlight-box">
      <div class="flex-direction-column">
        <div *ngIf="vibrationReport.no_new_measurement" class="last-status-text">LAST STATUS</div>
        <div class="status-container">
          <div class="icon-title-container">
            <div #machineIconContainer *ngIf="vibrationReport?.no_new_measurement"
              class="machine-icon-container status-nm">
              <mat-icon style="font-size: 50px;">graphic_eq</mat-icon>
            </div>
            <div #machineIconContainer class="machine-icon-container" [ngClass]="vibrationStatusColorClass"
             >
              <mat-icon style="font-size: 50px;">graphic_eq</mat-icon>
            </div>
            <div>
              <h2 style="margin-bottom: 0px;"> Machine status </h2>
              <div class="report-date">{{vibrationReportDate | date: "d MMMM yyyy"}}</div>
            </div>
          </div>

          <div style="display: flex; align-items: center;">
            <div class="status not-measured" *ngIf="vibrationReport?.no_new_measurement">
              {{ vibrationReport?.is_maintenance_period ? 'MAINTENANCE PERIOD' : 'NOT MEASURED'}}</div>
            <div class="status" [ngClass]="vibrationStatusColorClass">{{vibrationReport?.severity_level?.toUpperCase()}}
            </div>
          </div>
        </div>
      </div>



      <!-- Machine status -->
      <div *ngIf="vibrationReport?.machine_statuses.length" style="margin-bottom: 1em;">
        <table class="display-table">
          <thead>
            <tr>
              <th style="width: 11ch;">Type</th>
              <th style="width: 23ch;">Status</th>
              <th>Description</th>
              <th style="width: 17ch;">Fault</th>
              <th style="width: 17ch;">Severity level</th>
              <th style="width: 17ch;">Bearing type</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let machineStatus of vibrationReport?.machine_statuses; let i = index">
              <td style="height:35px">Machine</td>
              <td>
                <span [ngClass]="{
                              'label__neutral': machineStatus.status === 'None',
                              'label__good': machineStatus.status === 'Good' || machineStatus.status ==='Acceptance Report',
                              'label__warning': machineStatus.status === 'Warning' || machineStatus.status === 'Incomplete Data',
                              'label__alarm': machineStatus.status === 'Alarm'
                        }">
                  {{ machineStatus.status }}
                </span>
              </td>
              <td>{{machineStatus.comment || '–'}}</td>
              <td>{{machineStatus.defect?.name || '–'}}</td>
              <td>–</td>
              <td>–</td>
            </tr>

            <tr *ngFor="let oilStatus of vibrationReport?.oil_statuses; let i = index">
              <td style="height:35px">Oil</td>
              <td>
                <span [ngClass]="{
                              'label__neutral': oilStatus.status === 'None',
                              'label__good': oilStatus.status === 'Good' || oilStatus.status ==='Acceptance Report',
                              'label__warning': oilStatus.status === 'Warning' || oilStatus.status === 'Incomplete Data',
                              'label__alarm': oilStatus.status === 'Alarm'
                        }">
                  {{ oilStatus.status }}
                </span>
              </td>
              <td>{{oilStatus.comment || '–'}}</td>
              <td>{{oilStatus.defect?.name || '–'}}</td>
              <td>–</td>
              <td>–</td>
            </tr>

            <tr *ngFor="let bearingStatus of vibrationReport?.bearing_statuses; let i = index">
              <td style="height:35px">Bearing</td>
              <td>
                <span [ngClass]="{
                              'label__neutral': bearingStatus.status === 'None',
                              'label__good': bearingStatus.status === 'Good' || bearingStatus.status ==='Acceptance Report',
                              'label__warning': bearingStatus.status === 'Warning' || bearingStatus.status === 'Incomplete Data',
                              'label__alarm': bearingStatus.status === 'Alarm'
                        }">
                  {{ bearingStatus.status }}
                </span>
              </td>
              <td>{{bearingStatus.comment || '–'}}</td>
              <td>{{bearingStatus.defect?.name || '–'}}</td>
              <td>{{bearingStatus.bearing_severity_level || '–'}}</td>
              <td>{{bearingStatus.bearing?.manufacturer}}–{{bearingStatus.bearing?.type}}</td>
            </tr>

            <tr *ngFor="let electricalStatus of vibrationReport?.electrical_statuses; let i = index">
              <td style="height:35px">Electrical</td>
              <td>
                <span [ngClass]="{
                              'label__neutral': electricalStatus.status === 'None',
                              'label__good': electricalStatus.status === 'Good' || electricalStatus.status ==='Acceptance Report',
                              'label__warning': electricalStatus.status === 'Warning' || electricalStatus.status === 'Incomplete Data',
                              'label__alarm': electricalStatus.status === 'Alarm'
                        }">
                  {{ electricalStatus.status }}
                </span>
              </td>
              <td>{{electricalStatus.comment || '–'}}</td>
              <td>{{electricalStatus.defect?.name || '–'}}</td>
              <td>–</td>
              <td>–</td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>

    <!-- Recommendation -->
    <div *ngIf="vibrationReport?.recommendation" class="highlight-box">
      <h2>Recommendation</h2>
      <div markdown ngPreserveWhitespaces class="recommendation">
        {{vibrationReport?.recommendation}}
      </div>
    </div>

    <!-- Analysis -->
    <div *ngIf="vibrationReport?.analysis" class="highlight-box">
      <h2>Analysis</h2>
      <div markdown ngPreserveWhitespaces class="analysis">
        {{vibrationReport?.analysis}}
      </div>
    </div>

    <!-- Graph images -->
    <div class="images" *ngIf="vibrationReport?.report_images.length>0">
      <div *ngFor="let image of vibrationReport?.report_images;" style="cursor: pointer;">
        <img *ngIf="image.image_as_svg" src="{{ image.image_as_svg }}" (click)="openImage(image.image_as_svg)">
        <img *ngIf="!image.image_as_svg" src="{{ image.image }}" (click)="openImage(image.image)">
      </div>
    </div>

    <div class="highlight-box">
      <h2>Machine operation parameters</h2>
      <div class="machine-parameter-table">
        <table>
          <tbody>
            <tr>
              <td class="footer-tb-head">Running hours</td>
              <td class="footer-tb-td">
                {{vibrationReport?.running_hours ? vibrationReport?.running_hours + ' hours' : '–'}}
              </td>
            </tr>
          </tbody>
        </table>

        <table>
          <tbody>
            <tr>
              <td class="footer-tb-head">Speed</td>
              <td class="footer-tb-td">{{vibrationReport?.speed_rpm ? vibrationReport?.speed_rpm + ' RPM' : '–'}}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td class="footer-tb-head">Load</td>
              <td class="footer-tb-td">{{vibrationReport?.load_kw ? vibrationReport?.load_kw + ' kW' : '–'}}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td class="footer-tb-head">Pitch</td>
              <td class="footer-tb-td">{{vibrationReport?.pitch_pct ? vibrationReport?.pitch_pct + ' %' : '–'}}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td class="footer-tb-head">Electrical current</td>
              <td class="footer-tb-td">{{vibrationReport?.amps_a ? vibrationReport?.amps_a + ' A' : '–'}}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="foot-table-container">
        <table>
          <tbody>
            <tr>
              <td class="footer-tb-head">PO Number</td>
              <td class="footer-tb-td">–</td>
            </tr>
            <tr>
              <td class="footer-tb-head">SO Number</td>
              <td class="footer-tb-td">{{vibrationReport?.so_number || '–'}}</td>
            </tr>
          </tbody>
        </table>
        <table>

        </table>
        <table>
          <tbody>
            <tr>
              <td class="footer-tb-head">Analyzed by</td>
              <td class="footer-tb-td">{{analysedBy || '–'}}</td>
            </tr>
            <tr>
              <td class="footer-tb-head">Approved by</td>
              <td class="footer-tb-td">{{approvedBy || '–'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- OIL STATUS -->
  <div class="highlight-box" *ngIf="oilReport">
    <div class="status-container">
      <div class="icon-title-container">
        <div #machineIconContainer class="machine-icon-container" [ngClass]="oilReportStatus?.colorClass"
         >
          <mat-icon style="height: 50px; width: auto;" svgIcon="oil_report"></mat-icon>
        </div>
        <div>
          <h2 style="margin-bottom: 0px;"> Oil status </h2>
          <div class="report-date">{{oilReportDate | date: "d MMMM yyyy"}}</div>
        </div>
      </div>
      <div style="display: flex; align-items: center;">
        <div class="status" [ngClass]="oilReportStatus?.colorClass">
          {{oilReportStatus?.severityLevelString.toUpperCase()}}</div>
      </div>
    </div>

    <div class="color-bar" [ngClass]="oilReportStatus?.colorClass"></div>

    <!---------------------------------------------------------------------->
    <!--                    Main table                 									  -->
    <!---------------------------------------------------------------------->
    <div style="display:flex; width: 100%;">
      <div style="width: 50%;">
        <table class="table table-striped" style="border-bottom: solid 1px #dee2e6;">
          <tbody>
            <tr>
              <td class="footer-tb-head" scope="row">Date</td>
              <td>{{oilReport?.date_approved}}</td>
            </tr>
            <tr>
              <td class="footer-tb-head" scope="row">Manufacturer</td>
              <td>{{oilReport?.manufacturer}}</td>
            </tr>
            <tr>
              <td class="footer-tb-head" scope="row">Oil fluid type</td>
              <td>{{oilReport?.oilfluidtype}}</td>
            </tr>
            <tr>
              <td class="footer-tb-head" scope="row">Sample date</td>
              <td>{{oilReport?.sample_date}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style="width: 50%;">
        <table class="table table-striped" style="border-bottom: solid 1px #dee2e6;">
          <tbody>
            <tr>
              <td class="footer-tb-head" scope="row">Report reference</td>
              <td>{{oilReport?.laboratory_report_reference}}</td>
            </tr>
            <tr>
              <td class="footer-tb-head" scope="row">Model</td>
              <td>{{oilReport?.model}}</td>
            </tr>
            <tr>
              <td class="footer-tb-head" scope="row">Oil life</td>
              <td>{{oilReport?.oillife || '-'}}</td>
            </tr>
            <tr>
              <td class="footer-tb-head" scope="row">Serial number</td>
              <td>{{oilReport?.serial_number || '-'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>




<ng-template #loadingSpinner>
  <div>
    <mat-spinner diameter="25"></mat-spinner>
  </div>
</ng-template>
